import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { useWindowSize } from 'react-use';
import { Drawer } from '@development-nl/components-library';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { DragLayer } from '../../VideoTile';
import { DroppableMBoxCard } from '../Card';
import MBoxPanel from '../Panel';
import ShowMoreArea from '../ShowMoreArea';

const cardWidth = 120;
const cardGap = 12;
const cardHeight = 120;
const cardHeightWithGap = cardHeight + cardGap;

const useStyles = makeStyles()((theme, { panelShiftX, columnNum, rowNum }) => ({
    root: {
        '&.MuiPaper-root': {
            background: 'transparent',
            alignItems: 'center',
            pointerEvents: 'none',
            border: 0,
            left: `${panelShiftX}px`,
        },
    },
    panel: {
        pointerEvents: 'auto',
        overflow: 'hidden',
        position: 'relative',
    },
    boxes: {
        display: 'grid',
        gridColumnGap: `${cardGap}px`,
        gridTemplateColumns: `repeat(${columnNum}, minmax(${cardWidth}px, 1fr))`,
        overflow: 'hidden',
        margin: theme.spacing(0, 1),
        padding: 0,
        height: `${rowNum > 2 ? cardHeightWithGap * 2 : cardHeightWithGap * rowNum}px`,
        transition: 'height 0.3s',
        '&.showMore': {
            height: `${cardHeightWithGap * rowNum}px`,
            maxHeight: '70vh',
            overflow: 'auto',
        },
    },
    box: {
        marginBottom: `${cardGap}px`,
    },
}));

const MBoxesDndArea = ({
    isOpen,
    boxes,
    selectedItems,
    panelShiftX,
    showError,
    onClose,
    onDrop,
    onOpenBox,
    children,
}) => {
    const timerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isJustDropped, setIsJustDropped] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const { width } = useWindowSize();
    const boxNum = boxes.length;
    const columnNum = Math.min(Math.floor(width / (cardWidth + cardGap)) - 1, boxNum);
    const rowNum = Math.ceil(boxNum / columnNum);
    const hasMoreMboxes = rowNum > 2;

    const { classes, cx } = useStyles({ panelShiftX, columnNum, rowNum });

    const changeDrag = useCallback(isDrag => {
        setIsDragging(isDrag);
    }, []);

    const closePanel = () => {
        delayClose(0);
        onClose();
    };

    const selectMBox = mBoxId => {
        if (selectedItems && selectedItems.length !== 0) {
            if (mBoxId === 'quicklink') {
                showError({
                    title: 'Error',
                    subtitle: "Quicklink M-Box doesn't support clips.",
                });
            } else {
                onDrop(mBoxId, selectedItems);
            }
        } else {
            onOpenBox && onOpenBox(boxes.find(box => box.id === mBoxId));
        }
    };

    const dropItem = (mBoxId, items) => {
        setIsJustDropped(true);
        onDrop(mBoxId, items);
    };

    const delayClose = time => {
        if (isJustDropped) {
            timerRef.current = setTimeout(() => {
                onClose();
                setIsJustDropped(false);
                setShowMore(false);
            }, time);
        }
    };

    const clearTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    useEffect(() => {
        isOpen && setShowMore(false);
    }, [isOpen]);

    useEffect(() => clearTimer(), []);

    return (
        <DndProvider backend={Backend}>
            {children}
            <Drawer
                anchor="bottom"
                open={isOpen || isDragging || isJustDropped}
                PaperProps={{
                    elevation: 0,
                    classes: {
                        root: classes.root,
                    },
                }}
                variant={isOpen ? 'temporary' : 'persistent'}
                onClose={closePanel}
            >
                <MBoxPanel
                    className={classes.panel}
                    closePanel={closePanel}
                    data-cy="mBoxPanel"
                    title={
                        boxNum === 0
                            ? 'No M-Box'
                            : isDragging || (selectedItems && selectedItems.length !== 0)
                            ? 'Select an M-Box'
                            : 'M-Box'
                    }
                    onMouseEnter={clearTimer}
                    onMouseLeave={() => delayClose(1500)}
                >
                    <>
                        {boxNum > 0 && (
                            <div className={cx(classes.boxes, { showMore: showMore && hasMoreMboxes })}>
                                {boxes.map(box => {
                                    const { id, ...props } = box;

                                    return (
                                        <DroppableMBoxCard
                                            key={id}
                                            className={classes.box}
                                            dndActive={isDragging}
                                            height={cardHeight}
                                            onDrop={dropItems => dropItem(id, dropItems)}
                                            onSelect={() => selectMBox(id)}
                                            {...props}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        {hasMoreMboxes && (
                            <ShowMoreArea
                                height={cardHeightWithGap + cardGap}
                                visible={!showMore}
                                onShowing={setShowMore}
                            />
                        )}
                    </>
                </MBoxPanel>
            </Drawer>
            <DragLayer onDragChange={changeDrag} />
        </DndProvider>
    );
};

MBoxesDndArea.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    boxes: PropTypes.array,
    selectedItems: PropTypes.array,
    panelShiftX: PropTypes.number,
    showError: PropTypes.func.isRequired,
    columnNum: PropTypes.number,
    onClose: PropTypes.func,
    onDrop: PropTypes.func,
    onOpenBox: PropTypes.func,
    children: PropTypes.node,
};

MBoxesDndArea.defaultProps = {
    boxes: [],
    selectedItems: [],
    panelShiftX: 0,
    columnNum: 1,
    onClose: undefined,
    onDrop: undefined,
    onOpenBox: undefined,
    children: undefined,
};

export default MBoxesDndArea;
