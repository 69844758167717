import React from 'react';
import { Checkbox, FormControlLabel } from '@development-nl/components-library';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        fontWeight: '400',
        fontSize: '1.4rem',
        lineHeight: 1,
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    },
}));

const FolderFilterListButton = ({ folderItem, onClick }) => {
    const { name, isSelected } = folderItem;
    const { classes } = useStyles();

    return (
        <FormControlLabel
            className={classes.root}
            control={<Checkbox checked={isSelected} data-cy="checkboxElement" onClick={onClick} />}
            label={name}
        />
    );
};

FolderFilterListButton.propTypes = {
    folderItem: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

FolderFilterListButton.defaultProps = {
    folderItem: null,
    onClick: () => null,
};

export default FolderFilterListButton;
