import { useHistory, useLocation, useParams } from 'react-router-dom';

const useCustomNavigation = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const urlParams = new URLSearchParams(window.location.search);

    const push = (to, search = {}) => {
        const searchString = Object.keys(search)
            .map(key => `${key}=${search[key]}`)
            .join('&');

        history.push(`${to}?${searchString}`);
    };

    const replaceQuery = queryObject => {
        const search = Object.keys(queryObject)
            .map(key => `${key}=${queryObject[key]}`)
            .join('&');
        history.push({ search });
    };

    const updateQuery = queryObject => {
        const originalSearchObject = Object.fromEntries(new URLSearchParams(window.location.search || '')) || {};

        let updatedQueryObject = { ...originalSearchObject, ...queryObject };

        //Remove undefined params
        const search = Object.keys(updatedQueryObject)
            .filter(key => updatedQueryObject[key] !== undefined)
            .map(key => `${key}=${updatedQueryObject[key]}`)
            .join('&');

        history?.push({ search });
    };

    const currentSearchObject = Object.fromEntries(new URLSearchParams(window.location.search || '')) || {};

    return {
        push,
        replaceQuery,
        updateQuery,
        currentSearchObject,
        history,
        location,
        params,
        urlParams,
    };
};

export default useCustomNavigation;
