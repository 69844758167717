import React from 'react';
// TODO ICON - replace icon with equivalent from @development-nl/icons-library
import { ReactComponent as SearchIcon } from '@mediabank/assets/icons/search-mag-glass-icon.svg';
import { Input, InputAdornment } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        color: theme.palette.text.primary,
        borderRadius: theme.spacing(2),
        border: theme.palette.border.light,
        '&:hover': {
            backgroundColor: theme.palette.background.default,
        },
        padding: theme.spacing(0, 1),
        width: '100%',
        '& svg': {
            fill: theme.palette.text.primary,
        },
    },
}));

const FolderSearchInput = ({ onChange, ...forwardProps }) => {
    const { classes } = useStyles();

    return (
        <Input
            autoFocus={true}
            className={classes.root}
            disableUnderline={true}
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            }
            onChange={onChange}
            {...forwardProps}
        />
    );
};

FolderSearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default FolderSearchInput;
