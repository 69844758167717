import { makeStyles } from 'tss-react/mui';

export const useEditMetadataModalStyle = makeStyles()(theme => ({
    root: {
        '& .MuiFormLabel-root': {
            marginBottom: theme.spacing(3),
        },
    },
    content: {
        color: theme.custom.accentLight[0.6],
        overflowY: 'visible',
        '& p': {
            lineHeight: '21px',
        },
    },
    dividerLine: {
        marginTop: theme.spacing(2),
    },
    halfWidth: {
        width: '50%',
    },
    highlight: {
        fontWeight: 500,
    },
    iconWrapper: {
        '& svg': {
            height: 18,
            fill: theme.custom.accentLight[0.6],
        },
    },
    iconButton: {
        minWidth: 0,
        border: 'none',
        fill: 'none',
        stroke: theme.palette.text.primary,
    },
    title: {
        textAlign: 'start',
    },
    buttonBorder: {
        border: 'none',
    },
}));
