import { makeStyles } from 'tss-react/mui';

export const useScrollableContainerStyles = makeStyles()(() => ({
    container: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    scrollableXContainer: {
        height: '100%',
        overflow: 'auto',
    },
}));
