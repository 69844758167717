import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

/**
 * useQueryParamsObj - custom hook that combines `useLocation().search`
 *                     with `queryString.parse`
 *
 * NOTE: since this is a hook, it should only be used in function components
 *
 * @returns {Object} dictionary of the window's current query parameters
 */
const useQueryParamsObj = () => queryString.parse(useLocation().search);

export { useQueryParamsObj };
