import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenuItem } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';

import mBoxSlice from '../../store/mBox';
import { usePopperMenu } from '../PopperMenu/PopperMenuContext';
import { selectSelectedAssets } from '../SearchResult/selectors';

const {
    selectors: { getMBoxSlice },
    actions: { setPanelOpen, addAssets },
} = mBoxSlice;

const MboxContextList = ({ contextAsset, isIndividualMenu }) => {
    const dispatch = useDispatch();
    const { boxes } = useSelector(getMBoxSlice);
    const { closePopperMenu } = usePopperMenu();
    const selectedAssets = useSelector(selectSelectedAssets);

    const handleOnClick = (event, boxId) => {
        event.stopPropagation();
        closePopperMenu();
        if (boxId && contextAsset) {
            dispatch(addAssets({ targetMBoxIds: [boxId], assets: isIndividualMenu ? [contextAsset] : selectedAssets }));
            dispatch(setPanelOpen(true));
        }
    };

    const clickMenuItem = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    const wrapperStyle = {
        maxHeight: '70vh',
        overflowY: 'auto',
    };

    if (!boxes.length || boxes.length === 0) {
        return <ContextMenuItem label="No M-Box" onClick={clickMenuItem} />;
    } else {
        return (
            <div style={wrapperStyle}>
                {boxes
                    .filter(box => !Boolean(box?.options?.forcePublishingForm))
                    .map(box => (
                        <ContextMenuItem
                            key={box?.id}
                            label={box?.shortName}
                            onClick={e => handleOnClick(e, box?.id)}
                        />
                    ))}
            </div>
        );
    }
};

MboxContextList.propTypes = {
    contextAsset: PropTypes.object,
    isIndividualMenu: PropTypes.bool,
};

MboxContextList.defaultProps = {
    contextAsset: null,
    isIndividualMenu: false,
};

export default MboxContextList;
