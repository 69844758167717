import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenuItem } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';

import mBoxSlice from '../../../store/mBox';
import { selectSelectedAssets } from '../../SearchResult/selectors';
import { usePopperMenu } from '../PopperMenuContext';

const {
    actions: { addAssets, setOpenedBoxId },
} = mBoxSlice;

const QuickLinkContextItem = ({ contextAsset, isIndividualMenu }) => {
    const { closePopperMenu } = usePopperMenu();
    const selectedAssets = useSelector(selectSelectedAssets);
    const dispatch = useDispatch();

    const handleClick = async event => {
        event.stopPropagation();
        closePopperMenu();
        await dispatch(
            addAssets({ targetMBoxIds: 'quicklink', assets: isIndividualMenu ? [contextAsset] : selectedAssets })
        );
        dispatch(setOpenedBoxId('quicklink'));
    };

    return <ContextMenuItem label="Send as quicklink" onClick={handleClick} />;
};

QuickLinkContextItem.propTypes = {
    contextAsset: PropTypes.object,
    isIndividualMenu: PropTypes.bool,
};

QuickLinkContextItem.defaultProps = {
    contextAsset: null,
    isIndividualMenu: false,
};

export default QuickLinkContextItem;
