import React from 'react';
import { ReactComponent as MoreIcon } from '@mediabank/assets/icons/more-horizontal.svg';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        padding: 4,
        margin: 2,
        borderRadius: '50%',
        color: theme.custom.accentLight[0.9],
        backgroundColor: theme.custom.accentDark[0.2],
        '&:hover': {
            backgroundColor: theme.custom.accentDark[0.4],
        },
        '&:focus': {
            border: `1px solid ${theme.custom.focused[0.5]}`,
        },
    },
    moreIcon: {
        color: theme.custom.accentLight[0.9],
        width: '12px',
        height: '12px',
    },
}));

const PopperMenuButton = ({ onClick, size, iconClassName }) => {
    const { classes, cx } = useStyles();

    return (
        <IconButton
            aria-haspopup="false"
            aria-label="open"
            className={classes.root}
            disableRipple={true}
            size={size}
            onClick={onClick}
        >
            <MoreIcon className={cx(classes.moreIcon, iconClassName)} />
        </IconButton>
    );
};

PopperMenuButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    iconClassName: PropTypes.string,
};

PopperMenuButton.defaultProps = {
    size: 'medium',
    iconClassName: '',
};

export default PopperMenuButton;
