import { updateUrlQueryWithSearchParams } from '@mediabank/utils';
import { combineEpics, ofType } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';

import {
    clearSearchEpicAction,
    loadSmartSearchAction,
    resetSearchAction,
    setLoadingAction,
} from '../../components/AdvancedSearch/actions';
import { HEADER_CLEAR_QUICKFILTER } from '../../components/Header/constants';
import {
    SEARCH_PROVIDER_CLEAR_COLLECTION,
    SEARCH_PROVIDER_LOAD_COLLECTION,
    SEARCH_PROVIDER_REQUEST_DEFAULT,
    SEARCH_PROVIDER_RESET_RESULT,
    SEARCH_PROVIDER_SUBMIT_QUERY,
} from '../../framework/constants';
import { assetsService, smartSearchService } from '../../services';
import { translateResponse } from '../../store/advancedSearch/utils';
import searchResults from '../../store/assets/assetSearchResult';
import breadcrumbs from '../../store/breadcrumbs';
import calendar from '../../store/calendar';
import collections from '../../store/collections';
import folders from '../../store/folders/folders';
import searchProvider from '../../store/searchProvider';
import { initSearch as initSearchAction, populateWithEmptyData, searchSuccess, submitQuery } from './actions';
import { SEARCH_PROVIDER_INIT_SEARCH, SEARCH_PROVIDER_TRIGGER_INIT } from './constants';
import { selectQuery } from './selectors';

const { setQuery, resetQuery } = searchProvider.actions;
const { setLoading } = searchResults.actions;
const { setActiveCollectionId, setActiveSubCollectionId, resetCollection } = collections.actions;
const { setCurrentSearchMode } = breadcrumbs.actions;
const { resetCalendar } = calendar.actions;
const { resetFolders } = folders.actions;

const getDefaultSearchAssets = ({ action, addToastError, title, state }) => {
    const limit = action.payload?.limit || selectQuery(state)?.limit;

    return from(assetsService.search({ ...action.payload, limit })).pipe(
        map(searchSuccess),
        catchError(() =>
            concat(
                of(setLoadingAction(false)),
                of(populateWithEmptyData()),
                of(
                    addToastError({
                        title: title,
                    })
                )
            )
        )
    );
};

export const requestDefaultEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_REQUEST_DEFAULT),
        withLatestFrom(state$),
        distinctUntilChanged((_, [, state]) => state.searchProvider.page),
        switchMap(([action]) =>
            from(smartSearchService.getActive()).pipe(
                switchMap(({ data }) => {
                    if (data && data.data) {
                        const query = data.data && data.data.attributes ? translateResponse(data.data) : {};

                        return concat(
                            of(submitQuery({ ...query, ...action.payload })),
                            of(loadSmartSearchAction(query))
                        );
                    } else {
                        return of(submitQuery(action.payload));
                    }
                })
            )
        )
    );

export const resetSearchEpic = action$ =>
    action$.pipe(
        ofType(HEADER_CLEAR_QUICKFILTER),
        switchMap(() => concat(of(resetQuery()), of(submitQuery()), of(clearSearchEpicAction())))
    );

export const initSearchEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_INIT_SEARCH, SEARCH_PROVIDER_TRIGGER_INIT),
        debounceTime(200),
        withLatestFrom(state$),
        filter(([{ type }]) => type === SEARCH_PROVIDER_INIT_SEARCH),
        switchMap(([, state]) =>
            from(assetsService.search(selectQuery(state))).pipe(
                map(searchSuccess),
                catchError(() =>
                    concat(
                        of(setLoadingAction(false)),
                        of(populateWithEmptyData()),
                        of(
                            addToastError({
                                title:
                                    'Something went wrong while fetching the assets for this query. Please refresh your browser and try again.',
                            })
                        )
                    )
                )
            )
        )
    );

export const loadCollectionEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_LOAD_COLLECTION),
        withLatestFrom(state$),
        debounceTime(200),
        switchMap(([action, state]) =>
            concat(
                of(setLoading(action.payload)),
                of(resetQuery()),
                of(resetSearchAction()),
                of(setQuery(action.payload)),
                of(
                    updateUrlQueryWithSearchParams({
                        page: action.payload.page,
                        limit: action.payload.limit || state$.value.searchProvider.limit,
                    })
                ),
                getDefaultSearchAssets({
                    action,
                    addToastError,
                    title:
                        'Something went wrong while fetching the assets for this collection. Please refresh your browser and try again.',
                    state,
                })
            )
        )
    );

export const clearCollectionEpic = action$ =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_CLEAR_COLLECTION),
        switchMap(() => concat(of(setActiveCollectionId(null)), of(setActiveSubCollectionId(null))))
    );

export const watchForQueryChangesEpic = (action$, state$, { addToastError }) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_SUBMIT_QUERY),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { payload = {} } = action;
            const { page, limit, keyword, searchMode } = payload;

            if (!payload?.isCollectionView && !state?.collections?.activeId) {
                return concat(
                    of(setActiveCollectionId()),
                    of(setActiveSubCollectionId()),
                    of(setLoading(action.payload)),
                    of(setCurrentSearchMode(searchMode)),
                    of(updateUrlQueryWithSearchParams({ page, limit })),
                    of(setQuery(payload)),
                    keyword === ''
                        ? getDefaultSearchAssets({
                              action,
                              addToastError,
                              title:
                                  'Something went wrong while fetching the assets for this query. Please refresh your browser and try again.',
                              state,
                          })
                        : of(initSearchAction())
                );
            } else {
                return concat(
                    of(setLoading(payload)),
                    of(setQuery(payload)),
                    of(setCurrentSearchMode(searchMode)),
                    of(initSearchAction())
                );
            }
        })
    );

export const resetSearchResultEpic = (action$, state$) =>
    action$.pipe(
        ofType(SEARCH_PROVIDER_RESET_RESULT),
        switchMap(() => {
            const query = {
                limit: state$.value.config.data.DefaultItemsPerPage,
                page: 1,
                sortOrder: 'desc',
            };

            return concat(
                of(resetSearchAction()),
                of(resetQuery()),
                of(resetCollection()),
                of(resetCalendar()),
                of(resetFolders()),
                of(submitQuery(query))
            );
        })
    );

export default combineEpics(
    initSearchEpic,
    clearCollectionEpic,
    loadCollectionEpic,
    resetSearchEpic,
    resetSearchResultEpic,
    requestDefaultEpic,
    watchForQueryChangesEpic
);
