import React from 'react';
import { Box, Divider, Skeleton, Typography } from '@development-nl/components-library';
import { ReactComponent as ImageIcon } from '@mediabank/assets/icons/image-asset.svg';
import { ReactComponent as LinkIcon } from '@mediabank/assets/icons/link-icon.svg';
import { ReactComponent as LockIcon } from '@mediabank/assets/icons/lock.svg';
import { ReactComponent as MirroredIcon } from '@mediabank/assets/icons/mirrored.svg';
import { ReactComponent as MusicIcon } from '@mediabank/assets/icons/music-asset.svg';
import { Label, Tooltip, useCountLines } from '@mediabank/uikit-v5';
import { formatTime, isEmpty } from '@mediabank/utils';
import { styled } from '@mui/material';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { AssetWarningsTooltip } from './MetaDataTools';
import { OverlayBox } from './Thumbnail';

const toolsGeneralStyle = theme => ({
    backgroundColor: theme.custom.accentDark[0.4],
    borderColor: theme.custom.accentDark[0.4],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const useDefaultStyles = makeStyles()(theme => ({
    root: {
        ...toolsGeneralStyle(theme),
        borderRadius: theme.spacing(0, 0, 1, 1),
        color: theme.custom.accentLight[0.9],
    },
    footerRow: {
        display: 'flex',
        color: theme.custom.accentLight[0.5],
        flexFlow: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

const useToolsStyle = makeStyles()(theme => ({
    root: {
        ...toolsGeneralStyle(theme),
        '& svg': {
            fill: theme.palette.icon.light,
        },
    },
}));

const useIconWrapperStyles = makeStyles()(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        height: theme.spacing(3.75),
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& svg:not(first-child)': {
            marginLeft: 3,
        },

        '& svg': {
            height: 18,
            fill: theme.custom.accentLight[0.6],
        },
    },
}));

const useMetaDataInfoOverlayStyles = makeStyles()(() => ({
    root: {
        backgroundColor: 'rgba(21,24,25, 0.95)',
        overflow: 'auto',
    },
}));

const MetaDataTools = ({
    isAudio,
    isImageAsset,
    isLive,
    isLocked,
    isMirrored,
    uploadCompanyName,
    assetmeta,
    assetWarnings,
    durationInSec,
}) => {
    const { classes } = useToolsStyle();

    const { classes: iconWrapperStyles } = useIconWrapperStyles();

    const onIconClick = () => alert('Coming soon...');

    return (
        <Box className={classes.root}>
            <Box alignItems="center" display="flex">
                <Box pl={1}>{formatTime(durationInSec)}</Box>
                <Box alignItems="center" display="flex" fontSize={13.5}>
                    <Box minWidth={85}>{isLive && <Label size="large">LIVE</Label>}</Box>
                    {isAudio && <MusicIcon onClick={onIconClick} />}
                    {isImageAsset && <ImageIcon onClick={onIconClick} />}
                </Box>
                <Box className={iconWrapperStyles.root} pr={1}>
                    <AssetWarningsTooltip assetmeta={assetmeta} assetWarnings={assetWarnings} />

                    {isLocked && <LockIcon />}

                    {/* Hidden for now. Jira: MNT-3/MNT-36 */}
                    {false && <LinkIcon onClick={onIconClick} />}
                    {isMirrored && (
                        <Tooltip placement="top" title={`Asset shared from ${uploadCompanyName}.`}>
                            <span>
                                <MirroredIcon onClick={onIconClick} />
                            </span>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <Box>
                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.25)' }} />
            </Box>
        </Box>
    );
};

MetaDataTools.defaultProps = {
    assetmeta: {},
    assetWarnings: [],
    uploadCompanyName: '',
};

MetaDataTools.propTypes = {
    isAudio: PropTypes.bool.isRequired,
    isImageAsset: PropTypes.bool.isRequired,
    isLive: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
    isMirrored: PropTypes.bool.isRequired,
    uploadCompanyName: PropTypes.string,
    assetmeta: PropTypes.object.isRequired,
    assetWarnings: PropTypes.array.isRequired,
    durationInSec: PropTypes.number.isRequired,
};

const MetaDataInfoSkeleton = () => (
    <Box data-test="videoTile-assetMetadataInfo" px={1} py={0.5}>
        <Skeleton height={6} />
        <Skeleton height={6} width="80%" />
        <Skeleton height={6} />
        <Skeleton height={6} width="80%" />
    </Box>
);

const subTitleClasses = {
    root: 'SubTitle-root',
};

const SubTitleTypography = styled(Typography)(({ theme }) => ({
    [`&.${subTitleClasses.root}`]: {
        fontSize: 12,
        color: theme.custom.accentLight[0.4],
        lineHeight: 1.4,
        marginBottom: theme.spacing(0.375),
    },
}));

const titleClasses = {
    root: 'Title-root',
};

const TitleTypography = styled(Typography)(({ theme }) => ({
    [`&.${titleClasses.root}`]: {
        fontSize: 14,
        marginBottom: theme.spacing(0.375),
        position: 'relative',
        overflow: 'hidden',
        wordBreak: 'break-all',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
    },
}));

const dateClasses = {
    root: 'Date-root',
};

const DateTypography = styled(Typography)(({ theme }) => ({
    [`&.${dateClasses.root}`]: {
        fontSize: 12,
        color: theme.custom.accentLight[0.6],
        lineHeight: 1.4,
        marginBottom: theme.spacing(0.375),
    },
}));

const MetaDataInfo = ({ metaData }) => {
    const { classes } = useDefaultStyles();

    const [linesRef] = useCountLines({ fontSize: 16 });

    return (
        <Box className={classes.root} px={1} py={0.5}>
            <TitleTypography
                ref={linesRef}
                className={titleClasses.root}
                component={'div'}
                data-cy="videoTileTitle"
                display="block"
                style={{ lineHeight: 1.5 }}
                variant="body2"
            >
                {metaData.title}
                <Box>&nbsp;</Box>
            </TitleTypography>
            <SubTitleTypography className={subTitleClasses.root} display="block" noWrap={true} variant="caption">
                {metaData.eventType}
            </SubTitleTypography>
            <SubTitleTypography className={subTitleClasses.root} display="block" noWrap={true} variant="caption">
                {(metaData.eventInfo && metaData.eventInfo) || ''}
            </SubTitleTypography>
            <DateTypography className={dateClasses.root} display="block" noWrap={true} variant="caption">
                {metaData.date}
            </DateTypography>
        </Box>
    );
};

MetaDataInfo.propTypes = {
    metaData: PropTypes.shape({
        title: PropTypes.string,
        eventType: PropTypes.string,
        eventInfo: PropTypes.string,
        date: PropTypes.string,
        category: PropTypes.string,
    }).isRequired,
};

MetaDataInfo.defaultProps = {};

const MetaDataInfoOverlay = ({ metaDataList, assetmeta, ...props }) => {
    const { classes } = useMetaDataInfoOverlayStyles();

    return (
        <OverlayBox className={classes.root} p={1} {...props}>
            {(metaDataList || []).map((item, index) => {
                const value = assetmeta[item.key];

                if (!isEmpty(value)) {
                    return (
                        <Typography key={index} color="textPrimary" display="block" noWrap={true} variant="caption">
                            {!isEmpty(item.label) && `${item.label}:`} {assetmeta[item.key]}
                        </Typography>
                    );
                }

                return null;
            })}
        </OverlayBox>
    );
};

MetaDataInfoOverlay.propTypes = {
    metaDataList: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    assetmeta: PropTypes.object,
};

MetaDataInfoOverlay.defaultProps = {
    assetmeta: {},
};

const MetaDataInfoSimple = ({ metaData }) => {
    const { classes } = useDefaultStyles();

    return (
        <Box className={classes.root} px={1} py={0.5}>
            <Typography className={classes.titleRow} variant="body2">
                {truncate(metaData.title, { length: 24 })}
            </Typography>
        </Box>
    );
};

MetaDataInfoSimple.propTypes = {
    metaData: PropTypes.shape({
        title: PropTypes.string,
    }).isRequired,
};

export default {
    Info: MetaDataInfo,
    Skeleton: MetaDataInfoSkeleton,
    ExtraInfoOverlay: MetaDataInfoOverlay,
    Simple: MetaDataInfoSimple,
    Tools: MetaDataTools,
};
