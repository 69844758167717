import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { useScrollableContainerStyles } from './styles';

const ScrollableContainer = React.forwardRef(({ scrollBarPos, children, scrollableX, ...props }, ref) => {
    const { classes, cx } = useScrollableContainerStyles();

    return (
        <Box
            ref={ref}
            className={cx(!scrollableX ? classes.container : classes.scrollableXContainer)}
            mr={-scrollBarPos}
            pr={scrollBarPos}
            {...props}
        >
            {children}
        </Box>
    );
});

ScrollableContainer.propTypes = {
    children: PropTypes.node.isRequired,
    scrollBarPos: PropTypes.number,
    scrollableX: PropTypes.bool,
};

ScrollableContainer.defaultProps = {
    scrollBarPos: 2,
    scrollableX: false,
};

export default ScrollableContainer;
