import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsContext } from '@mediabank/uikit-v5';

import assetSlice from '../../../store/assets/asset';
import assetMetaKeysSlice, { getMetaKeys } from '../../../store/assets/metaKeys';
import contextMenuSlice from '../../../store/contextMenu';
import { selectSelectedAssets } from '../../SearchResult/selectors';
import EditMetadataModal from './EditMetadataModal';

const {
    selectors: { selectMetaKeysLoading, selectMetaKeysOptions },
} = assetMetaKeysSlice;

const {
    selectors: { getContextMenuSlice },
    actions: { toggleEditMetadataModal },
} = contextMenuSlice;

const ConnectedEditMetadataModal = () => {
    const dispatch = useDispatch();

    const { addToastError, addToastSuccess } = useContext(NotificationsContext);

    const { visibleEditMetadataModal } = useSelector(getContextMenuSlice);
    const metaKeyLoading = useSelector(selectMetaKeysLoading);
    const metaKeyOptions = useSelector(selectMetaKeysOptions);
    const isUpdateRequesting = useSelector(assetSlice.selectors.isRequesting);
    const selectedAssets = useSelector(selectSelectedAssets);

    useEffect(() => {
        visibleEditMetadataModal && dispatch(getMetaKeys({ getAll: true }));
    }, [dispatch, visibleEditMetadataModal]);

    const handleModalOnClose = () => dispatch(toggleEditMetadataModal());

    const handleUpdate = ({ metaKeyValues, lock }) => {
        if (metaKeyValues.map(meta => meta.metaValue).includes('')) {
            addToastError({ title: 'Error', subtitle: 'Metadata Value field is mandatory.' });

            return null;
        }

        if (selectedAssets.length > 0) {
            const selectedAssetIDs = selectedAssets.map(asset => asset.id);
            const assetMeta = metaKeyValues.reduce(
                (obj, item) => Object.assign(obj, { [item.metaKey]: item.metaValue }),
                {}
            );

            dispatch(assetSlice.bulkAssetMeta(selectedAssetIDs, assetMeta, lock))
                .then(response => {
                    if (response.status === 202) {
                        addToastSuccess({ title: 'Success', subtitle: 'Meta data changes were saved.' });
                        dispatch(toggleEditMetadataModal());
                    } else {
                        addToastError({ title: 'Error', subtitle: 'Could not update asset.' });
                    }
                })
                .catch(error =>
                    addToastError({
                        title: 'Error',
                        subtitle: `${error}`,
                    })
                );
        }
    };

    return (
        <>
            {visibleEditMetadataModal && (
                <EditMetadataModal
                    isRequesting={isUpdateRequesting}
                    metaKeyOptions={metaKeyOptions}
                    metaKeysIsLoading={metaKeyLoading}
                    open={visibleEditMetadataModal}
                    onClose={handleModalOnClose}
                    onUpdate={handleUpdate}
                />
            )}
        </>
    );
};

export default ConnectedEditMetadataModal;
