import { useSelector } from 'react-redux';

import userSlice from '../../store/user';
import {
    ROLE_APP_ADMIN,
    ROLE_ASSET_DELETE,
    ROLE_ASSET_METADATA_EDIT,
    ROLE_ASSET_MIRROR_UPDATE_META,
    ROLE_ATTACHMENT_CREATE,
    ROLE_CLIPLIST_CREATE,
    ROLE_CLIPLIST_DELETE,
    ROLE_CLIPLIST_EDIT,
    ROLE_CLIPLIST_VIEW,
    ROLE_DOWNLOAD_ALLOW,
    ROLE_MBOX_VIEW,
    ROLE_PROJECTS_CREATE,
    ROLE_PROJECTS_DELETE,
    ROLE_PROJECTS_EDIT,
    ROLE_STREAM_CLIP_DOWNLOAD_CREATE,
    ROLE_STREAMING_PLAYER,
    ROLE_SUPER_ADMIN,
} from './constants';

const useCheckUserPermission = () => {
    const roles = useSelector(userSlice.selectors.getUserRoles);

    const userHasRole = roleId => roles?.includes(roleId);
    const canCreateAttachment = userHasRole(ROLE_ATTACHMENT_CREATE);
    const canDeleteAsset = userHasRole(ROLE_ASSET_DELETE);
    const canEditMetaData = userHasRole(ROLE_ASSET_METADATA_EDIT);
    const canMirrorUpdateMetaData = userHasRole(ROLE_ASSET_MIRROR_UPDATE_META);
    const canViewClipList = userHasRole(ROLE_CLIPLIST_VIEW);
    const canCreateClipList = userHasRole(ROLE_CLIPLIST_CREATE);
    const canEditClipList = userHasRole(ROLE_CLIPLIST_EDIT);
    const canDeleteClipList = userHasRole(ROLE_CLIPLIST_DELETE);
    const canCreateFolder = userHasRole(ROLE_PROJECTS_CREATE);
    const canEditFolder = userHasRole(ROLE_PROJECTS_EDIT);
    const canDeleteFolder = userHasRole(ROLE_PROJECTS_DELETE);
    const canDownloadContent = userHasRole(ROLE_DOWNLOAD_ALLOW);
    const canViewMbox = userHasRole(ROLE_MBOX_VIEW);
    const canStreamClipDownloadCreate = userHasRole(ROLE_STREAM_CLIP_DOWNLOAD_CREATE);
    const canStreamingPlayer = userHasRole(ROLE_STREAMING_PLAYER);
    const isAdmin = userHasRole(ROLE_SUPER_ADMIN) || userHasRole(ROLE_APP_ADMIN);

    return {
        canCreateAttachment,
        canCreateClipList,
        canDeleteAsset,
        canDeleteClipList,
        canDownloadContent,
        canEditClipList,
        canCreateFolder,
        canEditFolder,
        canDeleteFolder,
        canEditMetaData,
        canMirrorUpdateMetaData,
        canStreamClipDownloadCreate,
        canStreamingPlayer,
        canViewClipList,
        canViewMbox,
        isAdmin,
    };
};

export default useCheckUserPermission;
