/**
 * Custom filter created for ListView Header
 * Those left out are customized values
 */
const getAssetListHeader = listColumns =>
    listColumns.filter(item => item.name !== 'PosterUrl' && item.name !== 'assetlocked');

const getLiveStreamMode = (currentTime, streamStartTime, streamEndTime) =>
    currentTime < streamStartTime
        ? 'scheduled'
        : currentTime > streamEndTime
        ? 'vod'
        : currentTime >= streamStartTime && currentTime <= streamEndTime
        ? 'live'
        : null;

const isSearchResultDisplayTypeRow = ({ dashboardSlug }) => {
    const currentlyOpenPanelsArr = dashboardSlug.split(',');

    return currentlyOpenPanelsArr.length > 2;
};

const navigatePanels = ({ history }) => {
    // if the viewer is already open,
    // we don't need to navigate on single-click
    if (history.location.pathname.includes('/dashboard/results,viewer,properties')) {
        return;
    }

    // if the collection is open,
    // open preview viewer panel with result
    if (history.location.pathname.includes('/dashboard/collections,results')) {
        return history.push({
            pathname: '/dashboard/collections,results,preview',
            search: history.location.search,
        });
    }

    // if the adcanced search is open,
    // open preview viewer panel with result
    if (history.location.pathname.includes('/dashboard/search,results')) {
        return history.push({
            pathname: '/dashboard/search,results,preview',
            search: history.location.search,
        });
    }

    // if the calendar is open,
    // open preview viewer panel with result
    if (history.location.pathname.includes('/dashboard/calendar,results')) {
        return history.push({
            pathname: '/dashboard/calendar,results,preview',
            search: history.location.search,
            ignoreUrlDateParam: true,
        });
    }

    // if the event view is open,
    // keep with the same path
    if (history.location.pathname.includes('/dashboard/results,viewer,timedevents')) {
        return history.push({
            pathname: '/dashboard/results,viewer,timedevents',
            search: history.location.search,
        });
    }

    // if the asset view is open,
    // keep with the same path
    if (history.location.pathname.includes('/dashboard/results,viewer')) {
        return history.push({
            pathname: '/dashboard/results,viewer',
            search: history.location.search,
        });
    }

    // otherwise, go to the two-panel "/results,viewer" route
    // unless we are already there
    const pathname = '/dashboard/results,preview';
    if (!history.location.pathname.includes(pathname)) {
        history.push({ pathname, search: history.location.search });
    }
};

export { getAssetListHeader, getLiveStreamMode, isSearchResultDisplayTypeRow, navigatePanels };
