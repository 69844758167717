import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ApplicationLoading } from '@mediabank/uikit-v5';
import { safeParseJSON } from '@mediabank/utils';

import useAuth from '../../hooks/useAuth';
import useCustomNavigation from '../../hooks/useCustomNavigation';
import configSlice from '../../store/config';

const {
    selectors: { getConfig },
} = configSlice;

const LandingPage = () => {
    const [url, setUrl] = useState(null);
    const authenticated = useAuth();
    const customNavigation = useCustomNavigation();

    const { DefaultApplication, ReactWorkspaces } = useSelector(getConfig) || {};

    useEffect(() => {
        const queryParams = { searchResultMode: 'tile' };
        try {
            if (typeof DefaultApplication !== 'undefined' && ReactWorkspaces !== '' && !url) {
                const view = safeParseJSON(ReactWorkspaces).find(
                    workspace => workspace.name.toLowerCase() === DefaultApplication.landingView?.library
                );
                const redirectTo = `/dashboard/${view?.path || ''}`;

                customNavigation.push(redirectTo, { ...queryParams });
                setUrl(redirectTo);
            }
        } catch (e) {
            customNavigation.push('/dashboard', { ...queryParams });
            setUrl('/dashboard');
        }
    }, [DefaultApplication, url, ReactWorkspaces, customNavigation]);

    const redirectUrl = authenticated ? url : '/login';

    if (!redirectUrl) return <ApplicationLoading />;

    return <Redirect to={redirectUrl} />;
};

export default LandingPage;
