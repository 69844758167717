import { useSelector } from 'react-redux';

import configSlice from '../../store/config';

const {
    selectors: { getConfig },
} = configSlice;

const useConfig = selectedConfigData => {
    const configs = useSelector(getConfig);
    const data = configs[selectedConfigData];

    return [data, configs];
};

export default useConfig;
