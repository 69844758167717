import React from 'react';
import { ReactComponent as LeftArrow } from '@mediabank/assets/icons/left-arrow.svg';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';

import { usePanelTabStyles } from './styles';

const PanelTab = ({
    onHideButtonClick,
    label,
    onClick,
    selected,
    shouldDisplayHideButton,
    variant,
    breadcrumbs,
    className,
    buttonLabelClass,
}) => {
    const { classes, cx } = usePanelTabStyles();
    const buttonClassName = cx(classes.generalBtnStyle, classes[variant], className, {
        [classes[`${variant}Selected`]]: selected,
    });

    return (
        <>
            <Button
                classes={{ label: buttonLabelClass }}
                className={buttonClassName}
                data-cy={`paneltabButton${selected ? 'Selected' : ''}`}
                disableRipple={true}
                variant="text"
                onClick={onClick}
            >
                {label}
                {variant === 'level1' && <div className={classes.bottomLine} />}
            </Button>
            {breadcrumbs && (
                <Box className={classes.breadcrumbsContainer} data-cy="breadcrumbs">
                    {breadcrumbs}
                </Box>
            )}
            {shouldDisplayHideButton && (
                <Box className={classes.hideButtonContainer}>
                    <Button
                        className={classes.hideButton}
                        color={'inherit'}
                        data-testid="PanelTab__hideButton"
                        variant={'contained'}
                        onClick={() => onHideButtonClick()}
                    >
                        <LeftArrow />
                        <span>Hide</span>
                    </Button>
                </Box>
            )}
        </>
    );
};

PanelTab.propTypes = {
    breadcrumbs: PropTypes.element,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onHideButtonClick: PropTypes.func,
    selected: PropTypes.bool,
    shouldDisplayHideButton: PropTypes.bool,
    variant: PropTypes.oneOf(['level1', 'level2']),
    className: PropTypes.string,
    buttonLabelClass: PropTypes.string,
};

PanelTab.defaultProps = {
    breadcrumbs: null,
    onClick: undefined,
    onHideButtonClick: () => {},
    selected: false,
    shouldDisplayHideButton: false,
    variant: 'level1',
    className: '',
    buttonLabelClass: '',
};

export default PanelTab;
