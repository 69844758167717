import React from 'react';
import { Box } from '@development-nl/components-library';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import FolderFilterListButton from './FolderFilterListButton';

const useStyles = makeStyles()(theme => ({
    root: {
        paddingBottom: theme.spacing(0.5),
    },
}));

const FolderFilterList = ({ folders, onClickFolderCheckbox }) => {
    const { classes } = useStyles();

    return (
        <>
            {(folders || []).map(folder => {
                const { id } = folder;

                return (
                    <Box key={id} className={classes.root}>
                        <FolderFilterListButton
                            className={classes.root}
                            folderItem={folder}
                            onClick={() => onClickFolderCheckbox(folder)}
                        />
                    </Box>
                );
            })}
        </>
    );
};

FolderFilterList.propTypes = {
    folders: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClickFolderCheckbox: PropTypes.func.isRequired,
};

FolderFilterList.defaultProps = {
    folders: [],
    onClickFolderCheckbox: () => null,
};

export default FolderFilterList;
