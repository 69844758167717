import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';

import { VideoTile } from '.';

export const DraggableVideoTile = props => {
    const { data, selectVideoTile, isSelected, selectedAssets, dataCy } = props;

    const [, drag, preview] = useDrag({
        item: {
            type: 'VideoTile',
            selectedItems: selectedAssets?.map(({ id, imgSrc, metaData, duration, attributes }) => ({
                id,
                imgSrc,
                metaData,
                duration,
                attributes,
            })),
        },
        begin: () => {
            // if the dragging item wasn't selected, meaning we have to trigger the action
            // as well as swap the selectedItems for dragLayer
            if (!isSelected) {
                const { id, imgSrc, metaData, duration, attributes } = data;
                selectVideoTile(id);

                return {
                    selectedItems: [
                        {
                            id,
                            imgSrc,
                            metaData,
                            duration,
                            attributes,
                        },
                    ],
                };
            }
        },
    });

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return (
        <div ref={drag} asset-id={data.id} className="selectable-asset" data-cy={dataCy}>
            <VideoTile
                isSelected={isSelected}
                onSelect={selectVideoTile}
                {...props}
                selectedAssetsNumber={selectedAssets.length}
            />
        </div>
    );
};

DraggableVideoTile.propTypes = {
    data: PropTypes.object.isRequired,
    selectVideoTile: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    selectedAssets: PropTypes.arrayOf(PropTypes.object),
    dataCy: PropTypes.string,
};

DraggableVideoTile.defaultProps = {
    isSelected: false,
    selectedAssets: [],
    dataCy: '',
};

export default DraggableVideoTile;
