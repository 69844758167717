import Zendesk from 'react-zendesk';

const ZENDESK_KEY = '6df57687-4738-4e15-825a-7206b5cfd61e';

const setting = {
    webWidget: {
        offset: {
            horizontal: '50px',
            vertical: '20px',
        },
        zIndex: 10,
        helpCenter: {
            filter: {
                category: '115000409711,115000409731,115000412792,360000031792',
            },
        },
    },
};

const ZenDesk = () => (
    <Zendesk defer={true} zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} />
);

export default ZenDesk;
