import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

import MBoxCard from './MBoxCard';

const DroppableWrapper = ({ onDrop, ...props }) => {
    const {
        options: { forcePublishingForm },
    } = props;
    const [dropped, setDropped] = useState(false);

    const [{ isOverCurrent }, drop] = useDrop({
        accept: 'VideoTile',
        drop: droppedItem => {
            setDropped(true);

            onDrop && onDrop(droppedItem.selectedItems);
        },
        collect: monitor => ({
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
        canDrop: () => !forcePublishingForm,
    });

    const dropEffectEnd = () => {
        setDropped(false);
    };

    return (
        <MBoxCard
            ref={drop}
            hover={isOverCurrent && !forcePublishingForm}
            selected={dropped}
            onTransitionEnd={dropEffectEnd}
            {...props}
        />
    );
};

DroppableWrapper.propTypes = {
    onDrop: PropTypes.func,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    options: PropTypes.shape({
        forcePublishingForm: PropTypes.bool,
    }),
};

DroppableWrapper.defaultProps = {
    onDrop: undefined,
    onSelect: undefined,
    className: '',
    options: {
        forcePublishingForm: false,
    },
};

export default DroppableWrapper;
