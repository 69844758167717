import React from 'react';
import { Box, Divider, Typography } from '@development-nl/components-library';
import PropTypes from 'prop-types';

import EditMetadataKeyValue from './EditMetadataKeyValue';
import { useEditMetadataModalStyle } from './styles';

const EditMetadataKeyValueList = ({ metaKeyValues, addOrUpdateMetaKeyValue, removeMetaKeyValue }) => {
    const { classes } = useEditMetadataModalStyle();

    if (metaKeyValues.length === 0) return null;

    return (
        <>
            <Divider className={classes.dividerLine} light={true} />
            <Box display="flex" mt={2}>
                <Box className={classes.halfWidth}>
                    <Typography component="label" variant="body2">
                        Metadata key
                    </Typography>
                </Box>
                <Box className={classes.halfWidth}>
                    <Typography component="label" variant="body2">
                        Value
                    </Typography>
                </Box>
            </Box>
            {metaKeyValues.map((metaKeyValue, index) => (
                <EditMetadataKeyValue
                    key={index}
                    addOrUpdateMetaKeyValue={addOrUpdateMetaKeyValue}
                    removeMetaKeyValue={() => removeMetaKeyValue(metaKeyValue)}
                    {...metaKeyValue}
                />
            ))}
        </>
    );
};

EditMetadataKeyValueList.propTypes = {
    metaKeyValues: PropTypes.array.isRequired,
    addOrUpdateMetaKeyValue: PropTypes.func.isRequired,
    removeMetaKeyValue: PropTypes.func.isRequired,
};

export default EditMetadataKeyValueList;
