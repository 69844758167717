export const SEARCH_PROVIDER_SEARCH_SUCCESS = '@@searchProvider/searchSuccess';
export const SEARCH_PROVIDER_POPULATE_WITH_EMPTY_DATA = '@@searchProvider/populateWithEmptyData';
export const SEARCH_PROVIDER_SUBMIT_QUERY = '@@searchProvider/submitQuery';
export const SEARCH_PROVIDER_REQUEST_DEFAULT = '@@searchProvider/requestDefault';
export const SEARCH_PROVIDER_LOAD_COLLECTION = '@@searchProvider/loadCollection';
export const SEARCH_PROVIDER_CLEAR_COLLECTION = '@@searchProvider/clearCollection';
export const SEARCH_PROVIDER_RESET_RESULT = '@@searchProvider/resetResult';
export const WORKSPACES = [
    {
        name: 'Full results',
        path: 'results',
        panelLabels: ['Results'],
    },
    {
        name: 'Asset view',
        path: 'results,viewer',
        panelLabels: ['Results', 'Player', 'Timeline'],
    },
    {
        name: 'Asset quick view',
        path: 'results,preview',
        panelLabels: ['Results', 'Preview'],
        urlParams: {
            tab: 'properties',
        },
    },
    {
        name: 'Collections',
        path: 'collections,results',
        panelLabels: ['Collections', 'Results'],
    },
    {
        name: 'Calendar',
        path: 'calendar,results',
        panelLabels: ['Calendar', 'Results'],
    },
    {
        name: 'Asset detailed view',
        path: 'results,viewer,properties',
        panelLabels: ['Results', 'Player', 'Properties', 'Timeline'],
        urlParams: {
            timeline: true,
            timelineOpen: true,
        },
        assetWorkspace: true,
    },
];
