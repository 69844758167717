import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsContext } from '@mediabank/uikit-v5';

import useCheckUserPermission from '../../../hooks/useCheckUserPermission';
import contextMenuSlice from '../../../store/contextMenu';
import folderEdit from '../../../store/folders/folderEdit';
import foldersSlice from '../../../store/folders/folders';
import userSlice from '../../../store/user';
import { selectSelectedAssets } from '../../SearchResult/selectors';
import AddToFolderModal from './AddToFolderDialog/AddToFolderDialog';

const {
    selectors: { getContextMenuSlice },
    actions: { toggleAddToFolderModal },
} = contextMenuSlice;

const ConnectedAddToFolderModal = () => {
    const dispatch = useDispatch();
    const [folders, setFolders] = useState([]);

    const { addToastError, addToastSuccess } = useContext(NotificationsContext);

    const { visibleAddToFolderModal } = useSelector(getContextMenuSlice);
    const projectFolders = useSelector(foldersSlice.selectors.folders);
    const selectedAssets = useSelector(selectSelectedAssets);
    const { profile } = useSelector(userSlice.selectors.getUser);

    const { canEditFolder } = useCheckUserPermission();

    useEffect(() => {
        if (!!projectFolders?.length) {
            const arrOfFolders = [];
            projectFolders.forEach(folder => {
                arrOfFolders.push({
                    id: folder.id,
                    name: folder.attributes?.projectName,
                    permissionGroups: folder.attributes?.permissionGroups,
                    ownerUserId: folder.attributes?.ownerUserId,
                    assets: folder.attributes?.relationships.assets,
                    isSelected: false,
                });
            });
            setFolders(arrOfFolders);
        }
    }, [projectFolders, visibleAddToFolderModal]);

    const checkedFolders = folders
        .map(element => element.isSelected && element.name)
        .filter(Boolean)
        .join(', ');

    const handleFolderCheckbox = selectedFolder => {
        const foldersInclAsset = folders.map(foldersChecked => {
            if (foldersChecked.id === selectedFolder.id) {
                const isSelected = !selectedFolder.isSelected;

                return { ...foldersChecked, isSelected };
            } else {
                return foldersChecked;
            }
        });

        setFolders(foldersInclAsset);
    };

    const handleModalOnClose = () => {
        setFolders([]);
        dispatch(toggleAddToFolderModal());
    };

    const handleOnSave = () => {
        if (checkedFolders?.length === 0) {
            addToastError({ title: 'Error', subtitle: 'You must select at least one Folder to perform a save.' });

            return;
        }
        const selectedAssetIDs = selectedAssets.length > 0 ? selectedAssets.map(asset => Number(asset.id)) : [];
        const selectedFolders = folders.filter(folder => !!folder.isSelected);

        selectedFolders.length &&
            selectedFolders.forEach(folder => {
                if (!(canEditFolder && Number(profile.userid) === Number(folder.ownerUserId))) {
                    addToastError({
                        cy: 'not-allowed-to-add-asset',
                        title: 'Error',
                        subtitle: 'You are not allowed to perform an update of folder.',
                    });

                    return;
                }
                let folderAssets = [];

                if (folder.assets?.length > 0) {
                    folderAssets = folder.assets.map(asset => Number(asset.id));
                }

                if (!!folder.isSelected) {
                    const data = {
                        attributes: {
                            assetIds:
                                folderAssets.length === 0
                                    ? selectedAssetIDs
                                    : Array.from(new Set([...selectedAssetIDs, ...folderAssets])),
                        },
                    };

                    dispatch(folderEdit.updateFolder({ id: folder.id, data }))
                        .then(() => {
                            addToastSuccess({
                                cy: 'allowed-to-add-asset',
                                title: 'Success',
                                subtitle: `Asset(s) ${selectedAssetIDs} added to folder ${folder.name}.`,
                            });
                        })
                        .catch(() => {
                            addToastError({
                                title: 'Error',
                                subtitle: 'Something went wrong.',
                            });
                        });
                }
            });
        handleModalOnClose();
    };

    return (
        <>
            {visibleAddToFolderModal && (
                <AddToFolderModal
                    checkedFolders={checkedFolders}
                    folders={folders}
                    open={visibleAddToFolderModal}
                    onClose={handleModalOnClose}
                    onFolderCheckbox={handleFolderCheckbox}
                    onSave={handleOnSave}
                />
            )}
        </>
    );
};

export default ConnectedAddToFolderModal;
