import React from 'react';
import { Box, ListItemText, MenuItem } from '@development-nl/components-library';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    wrapper: {
        display: 'flex',
        width: '100%',
    },
}));

const AttachmentMenuItem = React.forwardRef(
    ({ isDeleteIconVisible, isDownloadIconVisible, label, onClick, onClickDelete, onClickDownload }, ref) => {
        const { classes } = useStyles();

        return (
            <MenuItem
                ref={ref}
                className={classes.root}
                data-cy={`connected-attachment-menu-item-${label}`}
                onClick={onClick}
            >
                <Box className={classes.wrapper} data-cy={`connected-attachment-menu-item-${label}-text`}>
                    <ListItemText primary={label} />
                </Box>
                {isDownloadIconVisible && (
                    <Box pl={2.5}>
                        <SaveAltIcon
                            data-cy={`connected-attachment-menu-item-${label}-download`}
                            data-testid="attachment-menu-item-saveIcon"
                            onClick={onClickDownload}
                        />
                    </Box>
                )}
                {isDeleteIconVisible && (
                    <Box pl={1}>
                        <DeleteForeverIcon
                            data-cy={`connected-attachment-menu-item-${label}-delete`}
                            data-testid="attachment-menu-item-deleteIcon"
                            onClick={onClickDelete}
                        />
                    </Box>
                )}
            </MenuItem>
        );
    }
);

AttachmentMenuItem.propTypes = {
    isDeleteIconVisible: PropTypes.bool,
    isDownloadIconVisible: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickDownload: PropTypes.func,
};

AttachmentMenuItem.defaultProps = {
    isDeleteIconVisible: false,
    isDownloadIconVisible: false,
    onClickDownload: () => null,
};

export default AttachmentMenuItem;
