import React, { useState } from 'react';
import { Box, Button, Divider, Typography } from '@development-nl/components-library';
import { ReactComponent as LockIcon } from '@mediabank/assets/icons/lock.svg';
import { ReactComponent as PlusIcon } from '@mediabank/assets/icons/plus.svg';
import { Dialog, DialogActions, DialogContent, DialogTitle, InputField, Select } from '@mediabank/uikit-v5';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

import EditMetadataKeyValueList from './EditMetadataKeyValueList';
import { useEditMetadataModalStyle } from './styles';

const lockingOptions = [
    {
        value: -1,
        label: "Don't make changes",
    },
    {
        value: 1,
        label: 'Lock all',
    },
    {
        value: 0,
        label: 'Unlock all',
    },
];

const EditMetadataModal = ({ isRequesting, metaKeysIsLoading, metaKeyOptions, open, onClose, onUpdate }) => {
    const [metaKeyValues, setMetaKeyValues] = useState([]);
    const [isNewMetaKeyCreate, setIsCreateNewMetaKey] = useState(false);
    const [newMetaKey, setNewMetaKey] = useState('');
    const [selectedMetaKey, setSelectedMetaKey] = useState('');
    const [newMetaKeyError, setNewMetaKeyError] = useState('');
    const [lock, setLock] = useState(-1);

    const { classes, cx } = useEditMetadataModalStyle();

    const addOrUpdateMetaKeyValue = ({ metaKey, metaValue, isMetaKeyNew }) => {
        if (metaKey.length === 0) return null;

        setNewMetaKeyError('');

        if (metaKey === '_Create_') {
            setIsCreateNewMetaKey(true);
        } else {
            if (
                isNewMetaKeyCreate &&
                metaKeyOptions.findIndex(option => option.value.toLowerCase() === metaKey.toLowerCase()) >= 0
            ) {
                setNewMetaKeyError('Can not create already existing key');

                return null;
            }

            setIsCreateNewMetaKey(false);

            const metaKeys = metaKeyValues.map(meta => meta.metaKey);

            if (!metaKeys.includes(metaKey)) {
                setMetaKeyValues([...metaKeyValues, ...[{ metaKey, metaValue: '', isMetaKeyNew: isNewMetaKeyCreate }]]);
            } else {
                if (metaValue || isMetaKeyNew) {
                    metaKeyValues[metaKeys.indexOf(metaKey)] = {
                        ...metaKeyValues[metaKeys.indexOf(metaKey)],
                        metaKey,
                        metaValue,
                    };
                    setMetaKeyValues([...metaKeyValues]);
                }
            }
        }
    };

    const removeMetaKeyValue = ({ metaKey }) => {
        setMetaKeyValues(metaKeyValues.filter(key => key.metaKey !== metaKey));
    };

    const handleLockingOnChange = newLockValue => {
        setLock(newLockValue);
    };

    return (
        <Dialog
            data-cy="editMetadataModal"
            fullWidth={false}
            open={open}
            PaperProps={{
                style: {
                    overflowY: 'visible',
                },
            }}
        >
            <DialogTitle styles={{ title: classes.title }} onClose={onClose}>
                Edit metadata on selected
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Box mb={3}>
                    <Typography className={classes.highlight}>
                        This will change or update medata on the selected assets.
                    </Typography>
                    <Typography>Choose a metada key and a value from the list and press the plus-button.</Typography>
                    <Typography>You can even create new keys/value pairs.</Typography>
                </Box>
                <Box mb={0.5}>
                    <Typography className={classes.highlight} component="label" variant="body2">
                        Metadata key
                    </Typography>
                </Box>
                <Box display="flex">
                    <Box className={classes.root} mr={2}>
                        <Select
                            data-cy="metaKeySelector"
                            data-test="metaKeySelector"
                            disableOverrides={true}
                            isDisabled={metaKeysIsLoading}
                            isLoading={metaKeysIsLoading}
                            isSearchable={true}
                            name="metakeySelector"
                            options={[...[{ value: '_Create_', label: '-- Create new --' }], ...metaKeyOptions]}
                            placeholder={'Select or create new'}
                            value={selectedMetaKey}
                            onChange={setSelectedMetaKey}
                        />
                    </Box>
                    {isNewMetaKeyCreate && (
                        <Box mr={2}>
                            <InputField
                                data-test="newMetaKeyField"
                                error={!!newMetaKeyError}
                                helperText={newMetaKeyError}
                                placeholder={'Enter a new key'}
                                variant="filled"
                                onChange={setNewMetaKey}
                            />
                        </Box>
                    )}
                    <Box>
                        <Button
                            className={cx(classes.iconButton, classes.buttonBorder)}
                            color={'inherit'}
                            data-testid="addOrUpdatePlusBtn"
                            variant="contained"
                            onClick={() =>
                                addOrUpdateMetaKeyValue({
                                    metaKey: isNewMetaKeyCreate ? newMetaKey : selectedMetaKey,
                                    metaValue: '',
                                })
                            }
                        >
                            <PlusIcon height={12} width={12} />
                        </Button>
                    </Box>
                </Box>

                <EditMetadataKeyValueList
                    addOrUpdateMetaKeyValue={addOrUpdateMetaKeyValue}
                    isNewMetaKeyCreate={isNewMetaKeyCreate}
                    metaKeyValues={metaKeyValues}
                    removeMetaKeyValue={removeMetaKeyValue}
                />

                <Divider className={classes.dividerLine} light={true} />

                <Box alignItems="center" display="flex" justifyContent={'space-between'} mt={2}>
                    <Box alignItems="center" className={classes.halfWidth} display="flex">
                        <Box className={classes.iconWrapper} mr={1}>
                            <LockIcon />
                        </Box>
                        <Typography variant="h6">Locking options</Typography>
                    </Box>
                    <Box className={classes.halfWidth} display="flex">
                        <Select
                            options={lockingOptions.map(opt => ({ value: opt.value, label: opt.label }))}
                            value={lock}
                            onChange={handleLockingOnChange}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className={classes.buttonBorder} color={'inherit'} variant="contained" onClick={onClose}>
                    Cancel
                </Button>
                {isRequesting ? (
                    <CircularProgress data-testid="circularProgressIndicator" size={18} />
                ) : (
                    <Button
                        data-cy="updateBtn"
                        data-testid="updateBtn"
                        variant="contained"
                        onClick={() => onUpdate({ metaKeyValues, lock })}
                    >
                        Update
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

EditMetadataModal.propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    metaKeysIsLoading: PropTypes.bool.isRequired,
    metaKeyOptions: PropTypes.instanceOf(Array).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
};

EditMetadataModal.defaultProps = {
    onClose: () => null,
    onUpdate: () => null,
};

export default EditMetadataModal;
