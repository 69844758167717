import DateFnsUtils from '@date-io/date-fns';
import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

const dateFns = new DateFnsUtils();

export const assetListMapper = (assetList = []) =>
    assetList.map(asset =>
        asset.metaData
            ? { ...asset }
            : {
                  imgSrc: asset.attributes.assetmeta.Image,
                  posterCount: asset.attributes.assetmeta.NumberOfPosters,
                  selectedPosterCount: null,
                  metaData: {
                      title: asset.attributes.assetmeta.Title,
                      eventType: asset.attributes.assetmeta.SubTitle,
                      eventInfo: '',
                      Category: asset.attributes.assetmeta.Category,
                      SegmentType: asset.attributes.assetmeta.SegmentType,
                      date:
                          asset.attributes.assetcreated &&
                          dateFns.format(new Date(`${asset.attributes.assetcreated}00`), 'dd MMM yyyy'), // it's missing the '00' to be valid format
                      extra: [],
                      uploadCompanyName: asset.attributes.assetmeta.UploadCompanyName,
                      frameRate: asset.attributes.assetmeta.VideoFrameRate
                          ? asset.attributes.assetmeta.VideoFrameRate
                          : '24',
                  },
                  isLive: false,
                  isLocked: asset.attributes.assetlocked && Boolean(asset.attributes.assetlocked === '1'),
                  isMirrored:
                      asset.attributes.assetmeta.IsMirror && Boolean(asset.attributes.assetmeta.IsMirror === 'yes'),
                  id: asset.attributes.assetid,
                  duration: asset.attributes.assetduration && Number(asset.attributes.assetduration),
                  ProgressiveURL: asset.attributes.ProgressiveURL,
                  attributes: asset.attributes,
              }
    );

const initialState = {
    loading: true,
    data: [],
    loadingMore: false,
    hasMore: false,
    nextLink: null,
    nrOfItems: 0,
    selected: [],
    selectedAssets: [],
    selectedGroup: [],
    deselectedGroup: [],
    page: 1,
};

const assetSearchResultSlice = createSlice({
    name: 'assetSearchResult',
    initialState,
    reducers: {
        selectItem: (state, { payload: { id, multiSelect } }) => {
            if (multiSelect !== true) {
                state.selectedGroup = [];
                state.deselectedGroup = [];
                state.selected = [id];
                state.selectedAssets = state.data?.filter(({ id: itemId }) => itemId === id);
            } else {
                state.selectedGroup = [];
                state.deselectedGroup = [];
                if (state.selected.includes(id)) {
                    state.selected = state.selected.filter(item => item !== id);
                    state.selectedAssets = state.selectedAssets?.filter(({ id: itemId }) => itemId === id);
                } else {
                    state.selected.push(id);
                    const newfilteredAssets = state.data?.filter(({ id: itemId }) => itemId === id);
                    if (state.selectedAssets && state.selectedAssets.length) {
                        state.selectedAssets = [...state.selectedAssets, ...newfilteredAssets];
                    } else {
                        state.selectedAssets = newfilteredAssets;
                    }
                }
            }
        },
        selectItems: (state, { payload: { startID, endID } }) => {
            state.selectedGroup = [];
            const startIndex = state.data.findIndex(asset => asset.id === startID);
            const lastIndex = state.data.findIndex(asset => asset.id === endID);
            const selectedIdsGroup = state.data
                .slice(Math.min(startIndex, lastIndex), Math.max(startIndex, lastIndex) + 1)
                .map(item => item.id);
            state.selectedGroup.push(...selectedIdsGroup);
        },
        setSelectedItems: (state, { payload: { ids } }) => {
            state.selectedGroup = ids;
        },
        deselectItems: (state, { payload: { startID, endID } }) => {
            state.deselectedGroup = [];
            const startIndex = state.data.findIndex(asset => asset.id === startID);
            const lastIndex = state.data.findIndex(asset => asset.id === endID);
            const deselectedIdsGroup = state.data
                .slice(Math.min(startIndex, lastIndex), Math.max(startIndex, lastIndex) + 1)
                .map(item => item.id);
            state.deselectedGroup.push(...deselectedIdsGroup);
        },
        setSelectedStates: state => {
            if (state.selectedGroup) {
                const newSelected = state.selectedGroup.filter(selectedId => !state.selected.includes(selectedId));
                state.selected.push(...newSelected);
                if (state.data) {
                    const newSelectedAssets = state.data.filter(({ id }) => newSelected.includes(id));
                    state.selectedAssets.push(...newSelectedAssets);
                }
            }
            state.selected = state.selected.filter(selectedId => !state.deselectedGroup.includes(selectedId));
            state.selectedAssets = state.selectedAssets?.filter(
                selectedId => !state.deselectedGroup.includes(selectedId)
            );
            state.selectedGroup = [];
            state.deselectedGroup = [];
        },
        fetchStart: (state, action) => {
            state.loading = action.payload.page === 1;
            state.hasMore = false;
            state.loadingMore = action.payload.page !== 1;
        },
        fetchSuccess: (state, { payload: { data: assets, page } }) => {
            try {
                const parsedData = assetListMapper(assets.data);
                state.data = page === 1 ? parsedData : [...state.data, ...parsedData];
                state.loading = false;
                state.loadingMore = false;
                state.hasMore = get(assets, 'meta.noItems', 0) > state.data.length;
                state.nextLink = get(assets, 'links.next', null);
                state.nrOfItems = get(assets, 'meta.noItems', 0);
                state.page = page;
            } catch {
                state.loading = false;
                state.loadingMore = false;
                state.hasMore = false;
            }
        },
        setLoading: (state, { payload }) => {
            if (payload && payload.page === 1) {
                state.loading = true;
                state.hasMore = false;
            }
        },
        clearSelectItems: state => {
            state.selected = [];
            state.selectedGroup = [];
            state.deselectedGroup = [];
            state.selectedAssets = [];
        },
        setAssetPosterCount: (state, { payload: { assetId, poster, url } }) => {
            state.data = state.data.map(dataItem =>
                dataItem.id !== assetId
                    ? dataItem
                    : {
                          ...dataItem,
                          selectedPosterCount: poster,
                          selectedPosterURL: url,
                      }
            );
        },
        clear: () => initialState,
        clearPage: state => {
            state.loading = true;
            state.data = [];
            state.selected = [];
            state.selectedAssets = [];
            state.selectedGroup = [];
            state.deselectedGroup = [];
        },
    },
});

const getAllSearchResults = state => state.assetSearchResult.data;
const getSelectedIds = state => state.assetSearchResult.selected;

export default {
    ...assetSearchResultSlice,
    selectors: { getAllSearchResults, getSelectedIds },
};
