import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import userSlice from '../../store/user';
import PopperMenu from './PopperMenu';
import { PopperMenuContext } from './PopperMenuContext';

const ConnectedPopperMenu = ({ contextAsset, assetType, menuIconClassName, selectedAssetsNumber, position }) => {
    const userRoles = useSelector(userSlice.selectors.getUserRoles);

    const [menuPosition, setMenuPosition] = useState(null);

    useEffect(() => {
        let newPosition = null;

        if (position) {
            newPosition = {
                pageY: position.pageY,
                pageX: position.pageX,
            };
        }
        setMenuPosition(newPosition);
    }, [position]);

    return (
        <PopperMenuContext.Provider
            value={{
                closePopperMenu: () => setMenuPosition(null),
                setPopperMenuPosition: position => setMenuPosition(position),
            }}
        >
            <PopperMenu
                assetType={assetType}
                contextAsset={contextAsset}
                menuIconClassName={menuIconClassName}
                position={menuPosition}
                selectedAssetsNumber={selectedAssetsNumber}
                userRoles={userRoles}
            />
        </PopperMenuContext.Provider>
    );
};

ConnectedPopperMenu.propTypes = {
    assetType: PropTypes.string,
    menuIconClassName: PropTypes.string,
    selectedAssetsNumber: PropTypes.number,
    position: PropTypes.object,
    contextAsset: PropTypes.object,
};

ConnectedPopperMenu.defaultProps = {
    assetType: 'video',
    menuIconClassName: '',
    selectedAssetsNumber: 0,
    position: null,
    contextAsset: null,
};

export default ConnectedPopperMenu;
