import React, { createContext } from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { ToastContent } from './ToastContent';

const ToastContext = createContext({});

const Toast = ({ children = null, setShowOverlay, showToastInfo }) => {
    const { enqueueSnackbar } = useSnackbar();

    const addToast = ({ cy, persist, subtitle, title }) => {
        // for now, we only disallow duplicates when using the `persist` flag
        const preventDuplicate = persist;

        enqueueSnackbar(title, {
            content: key => (
                <Box data-cy={cy} data-testid={cy}>
                    {showToastInfo && (
                        <ToastContent
                            setShowOverlay={setShowOverlay}
                            subtitle={subtitle}
                            title={title}
                            toastKey={key}
                        />
                    )}
                </Box>
            ),
            persist,
            preventDuplicate,
        });
    };

    const appInfoValue = {
        addToastAppInfo: optionsObj => addToast({ ...optionsObj }),
    };

    return <ToastContext.Provider value={appInfoValue}>{children}</ToastContext.Provider>;
};

Toast.defaultProps = {
    setShowOverlay: null,
    showToastInfo: false,
};

Toast.propTypes = {
    children: PropTypes.node.isRequired,
    setShowOverlay: PropTypes.func,
    showToastInfo: PropTypes.bool,
};

export { Toast, ToastContext };
