import React, { useEffect, useState } from 'react';
import { ReactComponent as CancelIcon } from '@mediabank/assets/icons/clear-search-text-disabled.svg';
import { ReactComponent as SearchIcon } from '@mediabank/assets/icons/search-magnifier.svg';
import { ButtonBase, Input, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import { useInputStyles } from './styles';

const SearchBox = ({ disabled, value, onSearch, children, customClasses, className, ...rest }) => {
    const [keyword, setKeyword] = useState(value);

    useEffect(() => {
        setKeyword(value);
    }, [value]);

    const clear = () => {
        setKeyword('');
        onSearch('');
    };

    const onChange = e => {
        setKeyword(e.target.value);
    };

    const onKeyUp = e => {
        if (e.key === 'Escape') {
            setKeyword('');
        }

        if (e.key === 'Enter') {
            onSearch(keyword);
        }
    };

    const { classes: inputClasses, cx } = useInputStyles();

    return (
        <Input
            disabled={disabled}
            {...rest}
            className={cx(inputClasses.root, customClasses, className)}
            disableUnderline={true}
            endAdornment={
                <InputAdornment position="end">
                    {keyword && <CancelIcon className="cancelBtn" data-testid="cancelBtn" onClick={clear} />}
                    {children}
                    <ButtonBase className="textBtn" data-testid="searchBox-icon" onClick={() => onSearch(keyword)}>
                        Search
                    </ButtonBase>
                </InputAdornment>
            }
            fullWidth={true}
            inputProps={{
                'data-testid': 'searchBox-input',
            }}
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            }
            type="text"
            value={keyword}
            onChange={onChange}
            onKeyUp={onKeyUp}
        />
    );
};

SearchBox.propTypes = {
    onSearch: PropTypes.func.isRequired,
    customClasses: PropTypes.object,
    className: PropTypes.object,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    value: PropTypes.string,
};

SearchBox.defaultProps = {
    placeholder: 'Search...',
    customClasses: {},
    disabled: false,
    children: null,
    className: {},
    value: '',
};

export default SearchBox;
