export const ROLE_SUPER_ADMIN = 1;
export const ROLE_USER_VIEW = 2;
export const ROLE_USER_CREATE = 3;
export const ROLE_USER_EDIT = 4;
export const ROLE_GROUP_VIEW = 5;
export const ROLE_GROUP_CREATE = 6;
export const ROLE_GROUP_EDIT = 7;
export const ROLE_MBOX_VIEW = 8;
export const ROLE_MBOX_CREATE = 9;
export const ROLE_MBOX_EDIT = 10;
export const ROLE_MBOX_DELETE = 11;
export const ROLE_STORAGE_INTEGRATION_VIEW = 12;
export const ROLE_STORAGE_INTEGRATION_CREATE = 13;
export const ROLE_STORAGE_INTEGRATION_EDIT = 14;
export const ROLE_RULE_VIEW = 15;
export const ROLE_RULE_CREATE = 16;
export const ROLE_RULE_EDIT = 17;
export const ROLE_SCHEDULES_VIEW = 18;
export const ROLE_SCHEDULES_EDIT = 19;
export const ROLE_SMART_ACL_VIEW = 20;
export const ROLE_SMART_ACL_CREATE = 21;
export const ROLE_SMART_ACL_EDIT = 22;
export const ROLE_STATS_VIEW = 23;
export const ROLE_ADEX_FORMS_VIEW = 24;
export const ROLE_ADEX_FORMS_CREATE = 25;
export const ROLE_ADEX_FORMS_EDIT = 26;
export const ROLE_ADEX_FORMS_DELETE = 27;
export const ROLE_APP_ADMIN = 28;
export const ROLE_ASSET_METADATA_VIEW = 29;
export const ROLE_ASSET_METADATA_EDIT = 30;
export const ROLE_ASSET_METADATA_BATCH_EDIT = 31;
export const ROLE_ASSET_CREATE = 86;
export const ROLE_ASSET_DELETE = 32;
export const ROLE_ASSET_ARCHIVE = 33;
export const ROLE_ASSET_BACKUP = 34;
export const ROLE_ASSET_RESTORE_ARCHIVE = 35;
export const ROLE_ASSET_RESTORE_BACKUP = 36;
export const ROLE_ASSET_ACL_EDIT = 37;
export const ROLE_ASSET_MEDIAINFO_UPDATE = 38;
export const ROLE_ASSET_SET_POSTER = 39;
export const ROLE_ASSET_CREATE_PROXY = 40;
export const ROLE_ASSET_UPDATE_SCRUBBING_POSTER = 41;
export const ROLE_TAGS_OFFICIAL_VIEW = 42;
export const ROLE_TAGS_OFFICIAL_CREATE = 43;
export const ROLE_TAGS_OFFICIAL_EDIT = 44;
export const ROLE_TAGS_ADDITIONAL_VIEW = 45;
export const ROLE_TAGS_ADDITIONAL_CREATE = 46;
export const ROLE_TAGS_ADDITIONAL_EDIT = 47;
export const ROLE_CLIPLIST_VIEW = 48;
export const ROLE_CLIPLIST_CREATE = 49;
export const ROLE_CLIPLIST_EDIT = 50;
export const ROLE_CLIPLIST_DELETE = 51;
export const ROLE_SMART_SEARCH_VIEW = 52;
export const ROLE_SMART_SEARCH_CREATE = 53;
export const ROLE_SMART_SEARCH_EDIT = 54;
export const ROLE_SMART_SEARCH_DELETE = 55;
export const ROLE_PROJECTS_VIEW = 56;
export const ROLE_PROJECTS_CREATE = 57;
export const ROLE_PROJECTS_EDIT = 58;
export const ROLE_PROJECTS_DELETE = 59;
export const ROLE_GATEWAY_VIEW = 60;
export const ROLE_GATEWAY_CREATE = 61;
export const ROLE_GATEWAY_EDIT = 62;
export const ROLE_GATEWAY_DELETE = 63;
export const ROLE_UI_BUILDER_ADMIN = 64;
export const ROLE_ASSET_MIRROR_RULE_VIEW = 65;
export const ROLE_ASSET_MIRROR_RULE_CREATE = 66;
export const ROLE_ASSET_MIRROR_RULE_EDIT = 67;
export const ROLE_ASSET_MIRROR_RULE_DELETE = 68;
export const ROLE_STREAMING_PLAYER = 69;
export const ROLE_ASSET_MIRROR_UPDATE_META = 70;
export const ROLE_EDIT_ROLES = 71;
export const ROLE_DELETE_MIRRORED_ASSET = 72;
export const ROLE_USER_SUDO = 73;
export const ROLE_DOWNLOAD_ALLOW = 74;
export const ROLE_ASSET_RESTORE_MIRRORED_BACKUP = 75;
export const ROLE_VIEW_STREAMING_PLAYER = 76;
export const ROLE_ASSET_ACL_SHARED_METADATA_EDIT = 77;
export const ROLE_DEFAULT_APP_FOR_GROUP_EDIT = 79;
export const ROLE_DEFAULT_APP_FOR_COMPANY_EDIT = 80;
export const ROLE_PROJECTS_SHAREPUBLIC = 81;
export const ROLE_CLIPLIST_SHAREPUBLIC = 82;
export const ROLE_SMART_SEARCH_SHAREPUBLIC = 83;
export const ROLE_IMPORT_EVSLOG = 84;
export const ROLE_ATTACHMENT_CREATE = 85;
export const ROLE_STREAM_CLIP_DOWNLOAD_CREATE = 87;
export const ROLE_COMPANY_VIEW = 88;
export const ROLE_COMPANY_CREATE = 89;
export const ROLE_TAGLIST_SHAREPUBLIC = 90;
