import {
    SEARCH_PROVIDER_POPULATE_WITH_EMPTY_DATA,
    SEARCH_PROVIDER_REQUEST_DEFAULT,
    SEARCH_PROVIDER_RESET_RESULT,
    SEARCH_PROVIDER_SEARCH_SUCCESS,
    SEARCH_PROVIDER_SUBMIT_QUERY,
} from '../../framework/constants';
import { SEARCH_PROVIDER_INIT_SEARCH, SEARCH_PROVIDER_TRIGGER_INIT } from './constants';

export const initSearch = () => ({
    type: SEARCH_PROVIDER_INIT_SEARCH,
});

export const searchSuccess = result => ({
    type: SEARCH_PROVIDER_SEARCH_SUCCESS,
    payload: {
        result: {
            data: result?.data,
            config: {
                params: {
                    page: result.config.params.page,
                },
            },
        },
    },
});

export const populateWithEmptyData = () => ({
    type: SEARCH_PROVIDER_POPULATE_WITH_EMPTY_DATA,
    payload: {
        result: {
            data: [],
            config: {
                params: {
                    page: 1,
                },
            },
        },
    },
});

export const submitQuery = payload => ({
    type: SEARCH_PROVIDER_SUBMIT_QUERY,
    payload,
});

export const requestDefault = () => ({
    type: SEARCH_PROVIDER_REQUEST_DEFAULT,
});

export const triggerInit = () => ({
    type: SEARCH_PROVIDER_TRIGGER_INIT,
});

export const resetSearchResultEpicAction = () => ({
    type: SEARCH_PROVIDER_RESET_RESULT,
});
