import React from 'react';

export const useOuterClick = callback => {
    const callbackRef = React.useRef();
    const innerRef = React.useRef();

    React.useEffect(() => {
        callbackRef.current = callback;
    });

    React.useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);

        function handleClick(e) {
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
                callbackRef.current(e);
            }
        }
    }, []);

    return innerRef;
};

/**
 * @example
 * const outerClickRef = useOuterClick(e => console.log("what sould dispatch when clicks outside the container"));
 * <div ref={outerClickRef}>Click outside</div>
 */
