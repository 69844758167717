import React, { useEffect, useState } from 'react';
import { Box, Button } from '@development-nl/components-library';
import { ReactComponent as MinusIcon } from '@mediabank/assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '@mediabank/assets/icons/plus.svg';
import { assetsService } from '@mediabank/client';
import { InputField, Select } from '@mediabank/uikit-v5';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { useEditMetadataModalStyle } from './styles';

const EditMetadataKeyValue = ({ isMetaKeyNew, metaKey, metaValue, addOrUpdateMetaKeyValue, removeMetaKeyValue }) => {
    const [metaValues, setMetaValues] = useState({ data: [] });
    const [newMetaValue, setNewMetaValue] = useState('');
    const [isNewMetaValueCreate, setIsNewMetaValueCreate] = useState(false);
    const [newMetaValueError, setNewMetaValueError] = useState('');

    const { classes } = useEditMetadataModalStyle();

    const handleChangeNewMetaKeyValue = ({ value }) => {
        setNewMetaValue(value);
        newMetaValueValidator({ value });
    };

    const newMetaValueValidator = ({ value }) => {
        if (value === '') {
            setNewMetaValueError('Meta value cannot be empty');
        } else if (value.trim().length === 0) {
            setNewMetaValueError('Meta value cannot be only whitespace');
        } else if (
            metaValues.data.findIndex(metaValue => metaValue.value.toLowerCase() === value.toLowerCase()) !== -1
        ) {
            setNewMetaValueError('Cannot use already existing meta value');
        } else {
            setNewMetaValueError('');
        }
    };

    const handleAddNewMetaValue = () => {
        if (!newMetaValueError) {
            setMetaValues({
                loading: false,
                data: [...metaValues.data, { value: newMetaValue, label: newMetaValue }],
            });
            addOrUpdateMetaKeyValue({ metaKey, metaValue: newMetaValue });
            setIsNewMetaValueCreate(false);
        }
    };

    const handleMetaValueSelect = ({ metaKey, metaValue }) => {
        metaValue === '_Create_'
            ? setIsNewMetaValueCreate(true)
            : addOrUpdateMetaKeyValue({ metaKey, metaValue: metaValue });
    };

    useEffect(() => {
        if (metaKey) {
            setMetaValues({
                ...metaValues,
                loading: true,
            });
            (async () => {
                try {
                    const res = await assetsService.getMetaValues({ metaKeyId: metaKey });
                    const data = get(res, 'data.data.attributes.values').map(d => ({ value: d, label: d }));
                    setMetaValues({ loading: false, data });
                } catch (err) {
                    setMetaValues({ loading: false, data: [] });
                }
            })();
        }

        return () => {
            setMetaValues({ data: [] });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaKey]);

    return (
        <Box>
            <Box alignItems="center" data-testid="edit-metadata-keyvalue-row" display="flex" mt={1}>
                <Box sx={{ width: '50%' }}>
                    <InputField value={metaKey} variant="filled" />
                </Box>

                <Box alignItems="center" display="flex" sx={{ width: '50%' }}>
                    <Box flexGrow={1} mr={2}>
                        {isMetaKeyNew ? (
                            <InputField
                                data-testid="edit-metadata-value-input"
                                fullWidth={true}
                                placeholder={'Enter a value'}
                                value={metaValue}
                                variant="filled"
                                onChange={value => addOrUpdateMetaKeyValue({ metaKey, metaValue: value, isMetaKeyNew })}
                            />
                        ) : (
                            <Select
                                isLoading={metaValues.loading}
                                options={[{ label: '-- Create new --', value: '_Create_' }, ...metaValues.data]}
                                placeholder={'Select a value'}
                                value={metaValue}
                                onChange={selected => handleMetaValueSelect({ metaKey, metaValue: selected })}
                            />
                        )}
                    </Box>
                    <Box>
                        <Button
                            className={classes.iconButton}
                            color={'inherit'}
                            variant="contained"
                            onClick={removeMetaKeyValue}
                        >
                            <MinusIcon height={12} width={12} />
                        </Button>
                    </Box>
                </Box>
            </Box>
            {isNewMetaValueCreate && (
                <Box alignItems="center" data-testid="edit-metadata-newmetavalue-row" display="flex" mt={1}>
                    <Box className={classes.halfWidth} />
                    <Box alignItems="top" className={classes.halfWidth} display="flex">
                        <Box flexGrow={1} mr={2}>
                            <InputField
                                data-testid="edit-metadata-value-input"
                                error={!!newMetaValueError}
                                fullWidth={true}
                                helperText={newMetaValueError}
                                placeholder={'Enter a new metavalue'}
                                value={newMetaValue}
                                variant="filled"
                                onChange={value => {
                                    handleChangeNewMetaKeyValue({ value });
                                }}
                            />
                        </Box>
                        <Box>
                            <Button
                                className={classes.iconButton}
                                disabled={!!newMetaValueError}
                                variant="contained"
                                onClick={handleAddNewMetaValue}
                            >
                                <PlusIcon height={12} width={12} />
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

EditMetadataKeyValue.propTypes = {
    isMetaKeyNew: PropTypes.bool.isRequired,
    metaKey: PropTypes.string.isRequired,
    metaValue: PropTypes.string.isRequired,
    addOrUpdateMetaKeyValue: PropTypes.func.isRequired,
    removeMetaKeyValue: PropTypes.func.isRequired,
};

export default EditMetadataKeyValue;
