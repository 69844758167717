import { useEffect, useState } from 'react';

import { useQueryParamsObj } from '../useQueryParamsObj';

function useTimelineUrlParams() {
    const queryParamsObj = useQueryParamsObj();
    const URLTimeline = queryParamsObj?.timeline === 'true';
    const URLTimelineOpen = queryParamsObj?.timelineOpen === 'true';
    const [timeline, setTimeline] = useState(URLTimeline);
    const [timelineOpen, setTimelineOpen] = useState(URLTimelineOpen);

    useEffect(() => {
        setTimeline(URLTimeline);
    }, [URLTimeline, queryParamsObj]);

    useEffect(() => {
        setTimelineOpen(URLTimelineOpen);
    }, [URLTimelineOpen]);

    return [timeline, timelineOpen];
}

export default useTimelineUrlParams;
