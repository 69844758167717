import React from 'react';
import { Box, Button, Typography } from '@development-nl/components-library';
import { ContentLoading, Dialog, DialogActions, DialogContent } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.custom.accentLight[0.08],
    },
    content: {
        color: theme.custom.accentLight[0.8],
        minWidth: '20vw',
        maxHeight: '40vh',
        textAlign: 'center',
    },
    noBorder: {
        border: 'none',
    },
}));

const DeleteConfirmDialog = ({ attachment, loading, open, onClose, onConfirm }) => {
    const { classes } = useStyles();

    const { id, title } = attachment;

    return (
        <Dialog data-cy={'delete-confirm-dialog'} open={open} onClose={onClose}>
            <DialogContent classes={{ root: classes.root }} className={classes.content}>
                <Box mb={3}>
                    <Typography variant="h3">Please confirm</Typography>
                </Box>
                <Typography variant="body1">{`Sure you want to delete attachment "${title}"?`}</Typography>
            </DialogContent>
            <DialogActions classes={{ root: classes.root }}>
                <Button
                    className={classes.noBorder}
                    color={'inherit'}
                    data-cy={'delete-confirm-dialog-cancel-button'}
                    variant="contained"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                {loading ? (
                    <ContentLoading size={18} />
                ) : (
                    <Button
                        data-cy={'delete-confirm-dialog-confirm-button'}
                        variant="contained"
                        onClick={e => onConfirm(e, { id })}
                    >
                        Confirm
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

DeleteConfirmDialog.propTypes = {
    attachment: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

DeleteConfirmDialog.defaultProps = {
    loading: false,
};

export default DeleteConfirmDialog;
