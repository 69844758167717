import { useEffect, useState } from 'react';
import { getDocumentPoster } from '@mediabank/utils';

const useDocumentPoster = asset => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [image, setImage] = useState(null);
    const file = getDocumentPoster(asset);
    const isDocument = !!file;

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import(`@mediabank/assets/icons/file-posters/${file}`);
                setImage(response.default);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (file) {
            fetchImage();
        }
    }, [file]);

    return {
        loading,
        error,
        image,
        isDocument,
    };
};

export default useDocumentPoster;
