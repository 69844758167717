// helper function that returns params from search
// other than assetId, timeline or timelineOpen
export const searchWithoutTimlineOrAssetId = search => {
    let otherParams = '';
    const paramArray = search
        ?.substring(1)
        ?.split('&')
        ?.filter(string => !(string.includes('timeline') || string.includes('assetId')));

    if (paramArray.length) {
        otherParams = `&${paramArray.join('&')}`;
    }

    return otherParams;
};
