import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const getEvents = async ({ happeningId }) => {
    const response = await api.get(`/tags/happenings/${happeningId}/events`, {
        headers: mediabankHeaders(),
    });

    return response;
};

export default {
    getEvents,
};
