import { createSelector } from '@reduxjs/toolkit';

export const selectSortOrder = state => state.searchProvider.sortOrder;

export const selectLimit = state => Number(state.searchProvider.limit);

export const selectPage = state => Number(state.searchProvider.page);

export const selectOverlayKeys = ({ config }) => config.data.ThumbviewMetaOverlayKeys;

const listAssets = ({ assetSearchResult }) => assetSearchResult;

export const selectAssetsList = createSelector(listAssets, ({ data: assetList }) => ({ assetList }));

export const selectNrOfItems = createSelector(listAssets, data => data.nrOfItems);

export const selectSelectedAssetId = createSelector(listAssets, data => {
    if (data.selected[0] === undefined) {
        if (data.data.length === 0) {
            return 0;
        }

        return data.data[0].id;
    }

    return data.selected[0];
});

const selectedAssetIds = createSelector(listAssets, data => data.selected);
const selectedGroupIds = createSelector(listAssets, data => data.selectedGroup);
const deselectedGroupIds = createSelector(listAssets, data => data.deselectedGroup);

export const selectHeaderData = state => {
    const { nextLink, loading, loadingMore, hasMore } = state.assetSearchResult;

    return { nextLink, loading, loadingMore, hasMore };
};

export const selectSelectedAssets = createSelector(
    listAssets,
    selectedAssetIds,
    selectedGroupIds,
    deselectedGroupIds,
    (listAssets, selectedIds, selectedGroupIds, deselectedGroupIds) => {
        const allSelectedIds = new Set([...selectedIds, ...selectedGroupIds]);

        return Array.from(allSelectedIds)
            .filter(selectedId => !deselectedGroupIds.includes(selectedId))
            .map(selectedId => listAssets.data.find(asset => asset.id === selectedId))
            .filter(elem => elem !== undefined);
    }
);

export const selectCurrentPage = ({ assetSearchResult }) => assetSearchResult.page;

export const selectAssetWarnings = ({ config }) => config.data.AssetWarnings;

export const selectListViewColumns = ({ config }) => config.data.ListviewColumns;

export const selectConfigLimit = ({ config }) => Number(config.data.DefaultItemsPerPage);
