import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@development-nl/components-library';
import { ContextMenuItem, NotificationsContext } from '@mediabank/uikit-v5';
import { downloadOriginal } from '@mediabank/utils';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import contextMenuSlice, { deleteAttachment, getAttachments } from '../../../store/contextMenu';
import { usePopperMenu } from '../PopperMenuContext';
import AttachmentMenuItem from './AttachmentMenuItem';
import DeleteConfirmDialog from './DeleteConfirmDialog';

const {
    actions: { setSelectedAsset, toggleAttachmentModal },
} = contextMenuSlice;

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    loading: {
        paddingTop: '6px',
        height: 'auto',
    },
}));

const ConnectedAttachments = ({ canCreateAttachment, contextAsset, position, canDeleteAsset, mainMenuRef }) => {
    const [attachments, setAttachments] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { closePopperMenu } = usePopperMenu();
    const [visibleAttachmentDeleteModal, setVisibleAttachmentDeleteModal] = useState(false);
    const mainMenuEl = mainMenuRef?.getElementsByClassName('MuiMenu-paper')[0];
    const mainMenuElWidth = (mainMenuEl && mainMenuEl.clientWidth) || 0;
    const subMenuPosition = !!position && { pageX: position.pageX + mainMenuElWidth, pageY: position.pageY };
    const [deleteAttachmentState, setDeleteAttachment] = useState(undefined);
    const { addToastError, addToastSuccess } = useContext(NotificationsContext);

    const assetId = contextAsset.id || contextAsset.assetid;
    const assetTitle = contextAsset?.metaData?.title;

    const dispatch = useDispatch();
    const { classes } = useStyles();

    useEffect(() => {
        const fetchAttachments = async () => {
            setIsLoading(true);

            getAttachments(assetId)
                .then(data => {
                    const groupedByType = data.reduce((result, item) => {
                        let resultCopy = { ...result };
                        const {
                            attributes: { attachmentgroupname },
                        } = item;

                        if (!!attachmentgroupname && !resultCopy.hasOwnProperty(attachmentgroupname)) {
                            resultCopy[attachmentgroupname] = [];
                            resultCopy[attachmentgroupname].push(item);
                        } else if (!!attachmentgroupname) {
                            resultCopy[attachmentgroupname].push(item);
                        } else {
                            resultCopy[item.id] = item;
                        }

                        return resultCopy;
                    }, {});

                    setAttachments(groupedByType);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        };
        fetchAttachments();

        return () => {
            setAttachments({});
            setVisibleAttachmentDeleteModal(false);
            setDeleteAttachment(undefined);
        };
    }, [assetId]);

    const handleAddAttachmentClick = event => {
        event.stopPropagation();
        closePopperMenu();
        dispatch(setSelectedAsset({ assetId, assetTitle }));
        dispatch(toggleAttachmentModal());
    };

    const handleDeleteModalClose = event => {
        event.stopPropagation();
        closePopperMenu();
        setVisibleAttachmentDeleteModal(false);
    };

    const handleDeleteConfirm = (event, { id }) => {
        event.stopPropagation();

        deleteAttachment(id)
            .then(() => {
                addToastSuccess({
                    cy: 'notification-attachment-success',
                    title: 'Success',
                    subtitle:
                        'Asset successfully deleted. Please note that it can take up to one minute before the asset is removed from the search results.',
                });
            })
            .catch(error => {
                addToastError({
                    title: 'Error',
                    subtitle: error.message,
                });
            });
        closePopperMenu();
        setVisibleAttachmentDeleteModal(false);
    };

    const handleClickDownload = (event, { id, title }) => {
        event.stopPropagation();
        closePopperMenu();

        downloadOriginal({ assetid: id, assetmeta: { Title: title } });
    };

    const handleClickDelete = (event, { id, title }) => {
        event.stopPropagation();
        setDeleteAttachment({ id, title });

        setVisibleAttachmentDeleteModal(true);
    };

    const clickMenuItem = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    const hasAttachment = Object.keys(attachments).length > 0;
    const attachmentsKeys = hasAttachment && Object.keys(attachments);
    const hasMultipleAttachments = item => Array.isArray(attachments[item]);

    return (
        <Box className={classes.root}>
            {canCreateAttachment && (
                <ContextMenuItem label="Add attachment" showDivider={true} onClick={handleAddAttachmentClick} />
            )}
            {isLoading ? (
                <Box height={'auto'} p={1.5} pb={1}>
                    <CircularProgress size={20} />
                </Box>
            ) : (
                hasAttachment &&
                attachmentsKeys.map(item =>
                    hasMultipleAttachments(item) ? (
                        <ContextMenuItem
                            children={
                                <>
                                    {attachments[item].map(item => (
                                        <AttachmentMenuItem
                                            key={item.id}
                                            isDeleteIconVisible={canDeleteAsset}
                                            isDownloadIconVisible={item.attributes.assethasoriginal === '1'}
                                            label={item.attributes.assettitle}
                                            onClick={clickMenuItem}
                                            onClickDelete={e =>
                                                handleClickDelete(e, {
                                                    id: item.id,
                                                    title: item.attributes.assettitle,
                                                })
                                            }
                                            onClickDownload={e =>
                                                handleClickDownload(e, {
                                                    id: item.id,
                                                    title: item.attributes.assettitle,
                                                })
                                            }
                                        />
                                    ))}
                                </>
                            }
                            key={item}
                            label={item}
                            menuPosition={subMenuPosition}
                            onClick={clickMenuItem}
                        />
                    ) : (
                        <AttachmentMenuItem
                            key={item}
                            isDeleteIconVisible={canDeleteAsset}
                            isDownloadIconVisible={attachments[item].attributes.assethasoriginal === '1'}
                            label={attachments[item].attributes.assettitle}
                            onClick={clickMenuItem}
                            onClickDelete={e =>
                                handleClickDelete(e, {
                                    id: attachments[item].id,
                                    title: attachments[item].attributes.assettitle,
                                })
                            }
                            onClickDownload={e =>
                                handleClickDownload(e, {
                                    id: attachments[item].id,
                                    title: attachments[item].attributes.assettitle,
                                })
                            }
                        />
                    )
                )
            )}
            {!isLoading && !Object.keys(attachments).length && (
                <ContextMenuItem label="No attachment" onClick={clickMenuItem} />
            )}
            {visibleAttachmentDeleteModal && deleteAttachmentState && (
                <DeleteConfirmDialog
                    attachment={deleteAttachmentState}
                    open={visibleAttachmentDeleteModal}
                    onClose={handleDeleteModalClose}
                    onConfirm={handleDeleteConfirm}
                />
            )}
        </Box>
    );
};

ConnectedAttachments.propTypes = {
    canCreateAttachment: PropTypes.bool,
    canDeleteAsset: PropTypes.bool,
    contextAsset: PropTypes.object,
    mainMenuRef: PropTypes.instanceOf(Element),
    position: PropTypes.object,
};

ConnectedAttachments.defaultProps = {
    canCreateAttachment: false,
    canDeleteAsset: false,
    contextAsset: null,
    mainMenuRef: document.body,
    position: null,
};

export default ConnectedAttachments;
