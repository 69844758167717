import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchMode: '',
};

const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState,
    reducers: {
        setCurrentSearchMode: (state, searchMode) => {
            state.searchMode = searchMode.payload;
        },
        clear: () => initialState,
    },
});

export default breadcrumbsSlice;
