import React, { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import useAuth from '../../../apps/mediabank-next-transition/src/hooks/useAuth';
import useCustomNavigation from '../../../apps/mediabank-next-transition/src/hooks/useCustomNavigation';
import { Toast } from './Toast';

const AUTO_HIDE_DURATION_MS = 40000;

const useStyles = makeStyles()((theme, { showOverLay }) => ({
    overlay: {
        display: (showOverLay && 'flex') || 'none',
        zIndex: 100,
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        background: theme.palette.background.default,
        opacity: 0.5,
    },
}));

const ToastProvider = ({ children = null, customClasses }) => {
    const authenticated = useAuth();
    const customNavigation = useCustomNavigation();
    const { location } = customNavigation;
    const perfEntries = performance.getEntriesByType('navigation');
    const [showOverLay, setShowOverlay] = useState(
        authenticated && !location.pathname?.includes('apps') && perfEntries[0]?.type !== 'back_forward'
    );
    const { classes } = useStyles({ showOverLay });

    useEffect(() => {
        if (location.pathname.includes('apps')) {
            setShowOverlay(false);
        } else if (perfEntries[0]?.type !== 'back_forward') {
            setShowOverlay(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    return (
        <>
            <div className={classes.overlay} />
            <SnackbarProvider
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={AUTO_HIDE_DURATION_MS}
                classes={customClasses}
                maxSnack={1}
            >
                <Toast setShowOverlay={setShowOverlay} showToastInfo={showOverLay}>
                    {children}
                </Toast>
            </SnackbarProvider>
        </>
    );
};

ToastProvider.defaultProps = {
    customClasses: {},
};

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
    customClasses: PropTypes.object,
};

export { ToastProvider };
