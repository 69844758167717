import React, { useEffect, useState } from 'react';
import { useDragLayer } from 'react-dnd';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import NumIndicator from '../MBoxes/NumIndicator';
import Metadata from './MetaData';
import Thumbnail from './Thumbnail';

const tileSize = 120;

const useStyles = makeStyles()(theme => ({
    root: {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 1600,
        transition: 'transform 0.2s ease, opacity 0.4s ease',
        transformOrigin: 'center bottom',
        opacity: 0,
        '&.shrinkFadeOut-enter': {
            transform: 'scale(0.5)',
        },
        '&.shrinkFadeOut-exit-done': {
            transform: 'scale(0.3)',
            opacity: 0,
        },
    },
    dragging: {
        opacity: 1,
    },
    scaleDown: {
        transform: 'scale(0.5)',
        opacity: 0.5,
    },
    tile: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderBottomLeftRadius: theme.spacing(1.25),
        borderBottomRightRadius: theme.spacing(1.25),
        width: `${tileSize}px`,
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        '&:last-child': {
            position: 'relative',
        },
    },
    numIndicator: {
        position: 'absolute',
        right: theme.spacing(-1),
        bottom: theme.spacing(-1),
        zIndex: 1000,
    },
}));

const maxPrivewThumbNum = 3;
const opacityList = [1, 0.5, 0.2];

const SimpleTileDragLayer = ({ selectedItems }) => {
    const { classes } = useStyles();
    const displayItems = selectedItems.slice(Math.max(selectedItems.length - maxPrivewThumbNum, 0));

    return (
        <>
            <div>
                {displayItems.map((selectedItem, index) => {
                    const { imgSrc, metaData } = selectedItem;
                    const reverseIndex = displayItems.length - index - 1;
                    const offset = -reverseIndex * 5;

                    return (
                        <div
                            key={index}
                            className={classes.tile}
                            style={{
                                opacity: opacityList[reverseIndex],
                                zIndex: index,
                                top: offset,
                                left: offset,
                            }}
                        >
                            <Thumbnail.Container>
                                <Thumbnail.Img src={imgSrc} title={metaData?.title} />
                            </Thumbnail.Container>
                            <Metadata.Simple metaData={metaData} />
                        </div>
                    );
                })}
            </div>

            <NumIndicator className={classes.numIndicator} number={selectedItems.length} />
        </>
    );
};

SimpleTileDragLayer.propTypes = {
    selectedItems: PropTypes.array,
};

SimpleTileDragLayer.defaultProps = {
    selectedItems: [],
};

const checkCanDrop = monitor =>
    monitor.getTargetIds().some(id => monitor.isOverTarget(id) && monitor.canDropOnTarget(id));

// we have to keep the selected items and position in order to show the drop animation
// so instead of using the state from useDragLayer directly, we have to keep one more layer
const useDragLayerState = () => {
    const [dragItems, setDragItems] = useState([]);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const { canDrop, didDrop, isDragging } = useDragLayer(monitor => {
        const item = monitor.getItem();
        const currentOffset = monitor.getClientOffset();
        const canDrop = checkCanDrop(monitor);
        const isDragging = monitor.isDragging();

        item && setDragItems(item.selectedItems);
        currentOffset && setOffset(currentOffset);

        return {
            isDragging,
            canDrop,
            didDrop: monitor.didDrop(),
        };
    });

    return [isDragging, dragItems, offset, canDrop, didDrop];
};

const DragLayer = ({ onDragChange }) => {
    const { classes, cx } = useStyles();
    const [isDragging, dragItems, { x, y }, canDrop, didDrop] = useDragLayerState();

    useEffect(() => {
        onDragChange(isDragging);
    }, [isDragging, onDragChange]);

    return (
        <CSSTransition classNames="shrinkFadeOut" in={didDrop} timeout={0}>
            <div
                className={cx(classes.root, { [classes.scaleDown]: canDrop, [classes.dragging]: isDragging })}
                style={{ top: y - tileSize, left: x - tileSize / 2 }}
            >
                <SimpleTileDragLayer selectedItems={dragItems} />
            </div>
        </CSSTransition>
    );
};

DragLayer.propTypes = {
    onDragChange: PropTypes.func,
};

DragLayer.defaultProps = {
    onDragChange: undefined,
};

export default DragLayer;
