import React, { createContext, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import useTimelineUrlParams from '../../hooks/useTimelineUrlParams';

const LayoutContext = createContext();

export function useLayout() {
    return useContext(LayoutContext);
}

export const LayoutConsumer = LayoutContext.Consumer;

function resolveParams(params) {
    const result = {};
    for (var key of params.keys()) {
        result[key] = params.get(key);
    }

    return result;
}

function resolveSearchText(params) {
    let string = '';
    if (!params) return '';
    Object.keys(params).forEach((param, index) => {
        const prefix = index === 0 ? '?' : '&';
        string += `${prefix}${param}=${params[param]}`;
    });

    return string;
}

const LayoutProvider = ({ children }) => {
    const timelineUrlParams = useTimelineUrlParams();
    const history = useHistory();
    const timelineOpen = !!timelineUrlParams[1];
    const location = useLocation();
    const params = resolveParams(new URLSearchParams(location.search));

    const value = {
        toggleTimeline: () => {
            history.push({
                search: resolveSearchText({ ...params, timelineOpen: !timelineOpen }),
            });
        },
    };

    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

LayoutProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LayoutProvider;
