import React, { useRef, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@development-nl/components-library';
import { ReactComponent as CloseIcon } from '@mediabank/assets/icons/close-modal.svg';
import { Dialog, DialogActions, DialogContent, DialogTitle, InputField } from '@mediabank/uikit-v5';
import { ClickAwayListener } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import FolderFilterList from './FolderFilterList';
import FolderSearchInput from './FolderSearchInput';

const useHeaderStyles = makeStyles()((theme, { openList }) => ({
    boxList: {
        width: '500px',
        display: openList ? 'block' : 'none',
    },
    closeIcon: {
        fill: theme.palette.icon.light,
    },
    closeWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dialogContent: {
        margin: '0 auto',
    },
    folderInput: {
        width: '500px',
    },
    headerWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 100px',
    },
    scrollable: {
        backgroundColor: theme.palette.background.default,
        maxHeight: '150px',
        overflowY: 'scroll',
    },
    title: {
        fontWeight: 500,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: 'left',
    },
    cancelButton: {
        border: 'none',
    },
}));

const AddToFolderDialog = ({ folders, open, checkedFolders, onClose, onSave, onFolderCheckbox }) => {
    const nameRef = useRef(null);
    const [openList, setOpenList] = useState(false);
    const [search, setSearch] = useState('');
    const { classes } = useHeaderStyles({ openList });

    const handleFolderListToggle = () => {
        setOpenList(!openList);
    };

    const handleSearchInput = event => {
        setSearch(event.target.value);
    };

    const filteredFolders = search
        ? folders?.filter(event => event.name.toLowerCase().includes(search.toLowerCase()))
        : folders;

    return (
        <Dialog fullWidth={true} open={open}>
            <DialogTitle>
                <Box className={classes.headerWrapper}>
                    <Box>
                        <Typography className={classes.title}>Add To Folder Modal</Typography>
                    </Box>
                    <Box className={classes.closeWrapper}>
                        <IconButton data-test="close-button" onClick={onClose}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <InputField
                    className={classes.folderInput}
                    data-cy="addToFolderModalInput"
                    fullWidth={true}
                    inputRef={nameRef}
                    placeholder="Select the folder(s)"
                    value={checkedFolders}
                    variant="filled"
                    onClick={handleFolderListToggle}
                />
                <Box>
                    <Box className={classes.boxList} open={openList}>
                        <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={() => setOpenList(false)}
                        >
                            <Box className={classes.scrollable} p={1}>
                                <Box pb={1}>
                                    <FolderSearchInput
                                        placeholder="Search"
                                        value={search}
                                        onChange={event => handleSearchInput(event)}
                                    />
                                </Box>
                                <FolderFilterList folders={filteredFolders} onClickFolderCheckbox={onFolderCheckbox} />
                            </Box>
                        </ClickAwayListener>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancelButton} color={'inherit'} variant="contained" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    data-cy="addToFolder-saveBtn"
                    data-test="addToFolder-saveBtn"
                    variant="contained"
                    onClick={() => onSave()}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddToFolderDialog.propTypes = {
    folders: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    checkedFolders: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onFolderCheckbox: PropTypes.func,
};

AddToFolderDialog.defaultProps = {
    folders: [],
    checkedFolders: '',
    onClose: () => null,
    onSave: () => null,
    onFolderCheckbox: () => null,
};

export default AddToFolderDialog;
