import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ContextMenuItem, NotificationsContext } from '@mediabank/uikit-v5';
import PropTypes from 'prop-types';

import { assetSearchResult } from '../../../store/assets';
import assetSlice from '../../../store/assets/asset';
import contextMenuSlice from '../../../store/contextMenu';
import folderEdit from '../../../store/folders/folderEdit';
import foldersSlice from '../../../store/folders/folders';
import { selectConfigLimit, selectSelectedAssets } from '../../SearchResult/selectors';
import { usePopperMenu } from '../PopperMenuContext';

const actionTypes = {
    setPoster: 'setPoster',
    updateMediaInfo: 'updateMediaInfo',
    updateScrubbingPosters: 'updateScrubbingPosters',
    copyAssetLink: 'copyAssetLink',
    updateProxy: 'updateProxy',
};

const ActionsContextList = ({ contextAsset, isIndividualMenu }) => {
    const assetId = contextAsset.id || contextAsset.assetid;
    const originalPoster = contextAsset?.attributes?.assetmeta?.PosterFileName;

    const selectedAssets = useSelector(selectSelectedAssets);
    const selectedFolderDetails = useSelector(foldersSlice.selectors.folderDetails);
    const selectedFolderAssets = useSelector(foldersSlice.selectors.folderAssets);

    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const urlParamLimit = Number(urlParams.get('limit'));
    const configLimit = useSelector(selectConfigLimit);
    const defaultLimit = urlParamLimit || configLimit;

    const { toggleEditMetadataModal, toggleAddToFolderModal } = contextMenuSlice.actions;
    const { addToastSuccess, addToastError } = useContext(NotificationsContext);
    const { closePopperMenu } = usePopperMenu();

    const dispatch = useDispatch();

    const handleSetPoster = () => {
        const selectedAssetIDs = isIndividualMenu ? [assetId] : selectedAssets.map(asset => asset.id || asset.assetid);
        if (originalPoster) {
            dispatch(assetSlice.bulkAssetMeta(selectedAssetIDs, { PosterFileName: originalPoster }, 0))
                .then(response => {
                    if (response.status === 200 || 202) {
                        addToastSuccess({ title: 'Success', subtitle: 'Poster updated.' });
                    } else {
                        addToastError({ title: 'Error', subtitle: 'Could not update poster.' });
                    }
                })
                .catch(error =>
                    addToastError({
                        title: 'Error',
                        subtitle: `${error}`,
                    })
                );
        } else {
            addToastError({ title: 'Error', subtitle: 'No poster found.' });
        }
    };

    const handleUpdateMediaInfo = () => {
        const selectedAssetIDs = isIndividualMenu ? [assetId] : selectedAssets.map(asset => asset.id || asset.assetid);

        dispatch(assetSlice.bulkUpdateAssetMediaInfo(selectedAssetIDs, false))
            .then(response => {
                if (response?.status === 200 || 202) {
                    addToastSuccess({
                        title: 'Update media info',
                        subtitle: 'Media info got updated',
                    });
                } else {
                    addToastError({ title: 'Error', subtitle: 'Could not update media info.' });
                }
            })
            .catch(error =>
                addToastError({
                    title: 'Error',
                    subtitle: `${error}`,
                })
            );
    };

    const handleCopyAssetLink = () => {
        const theLink = `${window.location.origin}/apps/medialibrary/dashboard/results,preview?assetId=${assetId}`;

        navigator?.clipboard?.writeText?.(theLink);

        addToastSuccess({
            title: 'Link is on your clipboard!',
            subtitle: `The link is copied to the clipboard: ${theLink}`,
        });
    };

    const handleUpdateProxy = () => {
        const selectedAssetIDs = isIndividualMenu ? [assetId] : selectedAssets.map(asset => asset.id || asset.assetid);

        dispatch(assetSlice.bulkUpdateProxyFiles(selectedAssetIDs))
            .then(response => {
                if (response?.status === 200 || 202) {
                    addToastSuccess({
                        title: 'New Proxy',
                        subtitle: 'Successfully started generating new proxy. This may take a while.',
                    });
                } else {
                    addToastError({ title: 'Error', subtitle: 'Could not generate new proxy files.' });
                }
            })
            .catch(error =>
                addToastError({
                    title: 'Error',
                    subtitle: `${error}`,
                })
            );
    };

    const handleUpdateScrubbingPosters = () => {
        const selectedAssetIDs = isIndividualMenu ? [assetId] : selectedAssets.map(asset => asset.id || asset.assetid);

        dispatch(assetSlice.bulkGenerateScrubbingPosters(selectedAssetIDs, false))
            .then(response => {
                if (response?.status === 200 || 202) {
                    addToastSuccess({
                        title: 'Poster Update',
                        subtitle: 'Poster update command successful. This may take a few minutes to complete.',
                    });
                } else {
                    addToastError({ title: 'Error', subtitle: 'Could not update posters.' });
                }
            })
            .catch(error =>
                addToastError({
                    title: 'Error',
                    subtitle: `${error}`,
                })
            );
    };

    const clickEditMetadata = event => {
        event.stopPropagation();
        event.preventDefault();
        closePopperMenu();

        isIndividualMenu &&
            dispatch(
                assetSearchResult.actions.selectItem({
                    id: assetId,
                    multiSelect: false,
                })
            );

        dispatch(toggleEditMetadataModal());
    };

    const handleAddToFolder = event => {
        event.stopPropagation();
        event.preventDefault();
        closePopperMenu();

        isIndividualMenu &&
            dispatch(
                assetSearchResult.actions.selectItem({
                    id: assetId,
                    multiSelect: false,
                })
            );

        dispatch(toggleAddToFolderModal());
    };

    const handleRemoveFromFolder = event => {
        event.stopPropagation();
        event.preventDefault();
        closePopperMenu();
        const selectedAssetIDs = selectedAssets.length > 0 ? selectedAssets.map(asset => Number(asset.id)) : [];
        const keepAssets = selectedFolderAssets?.filter(asset => !selectedAssetIDs.includes(Number(asset.id)));
        const keepAssetIds = keepAssets?.length > 0 ? keepAssets.map(asset => Number(asset.id)) : [];

        if (selectedAssetIDs.length > 0) {
            const data = {
                attributes: {
                    assetIds: keepAssetIds,
                },
            };

            dispatch(folderEdit.updateFolder({ id: selectedFolderDetails.id, data }))
                .then(() => {
                    addToastSuccess({
                        cy: 'allowed-to-remove-asset',
                        title: 'Success',
                        subtitle: `Asset(s) ${selectedAssetIDs} deleted from folder ${selectedFolderDetails.attributes?.projectName}.`,
                    });
                    dispatch(
                        assetSearchResult.actions.fetchSuccess({
                            data: {
                                data: keepAssets,
                            },
                            limit: defaultLimit,
                            page: 1,
                        })
                    );
                    dispatch(foldersSlice.actions.updateAssets({ updatedFolderAssets: keepAssets }));
                })
                .catch(() => {
                    addToastError({
                        cy: 'error',
                        title: 'Error',
                        subtitle: 'Something went wrong.',
                    });
                });
        }
    };

    const isSetPosterDisabled = selectedAssets.length !== 1;
    const isCopyAssetLinkDisabled = selectedAssets.length !== 1;

    const handleClick = (event, actionName) => {
        event.stopPropagation();
        if (!actionName) return;
        closePopperMenu();
        switch (actionName) {
            case actionTypes.setPoster:
                return handleSetPoster();
            case actionTypes.updateMediaInfo:
                return handleUpdateMediaInfo();
            case actionTypes.copyAssetLink:
                return handleCopyAssetLink();
            case actionTypes.updateProxy:
                return handleUpdateProxy();
            case actionTypes.updateScrubbingPosters:
                return handleUpdateScrubbingPosters();
            default:
                return;
        }
    };

    return (
        <div>
            <ContextMenuItem dataTestId="menuitem-add-to-folder" label="Add To Folder(s)" onClick={handleAddToFolder} />
            <ContextMenuItem
                dataTestId="menuitem-remove-from-folder"
                disabled={!selectedFolderDetails?.id}
                label="Remove From Folder"
                onClick={handleRemoveFromFolder}
            />
            <ContextMenuItem
                dataTestId="menuitem-set-poster"
                disabled={isSetPosterDisabled}
                label="Set poster"
                onClick={e => handleClick(e, actionTypes.setPoster)}
            />
            <ContextMenuItem
                dataTestId="menuitem-update-media-info"
                label="Update Media Info"
                onClick={e => handleClick(e, actionTypes.updateMediaInfo)}
            />
            <ContextMenuItem
                dataTestId="menuitem-update-scrubbing-poster"
                label="Update Scrubbing Posters "
                onClick={e => handleClick(e, actionTypes.updateScrubbingPosters)}
            />
            <ContextMenuItem
                dataTestId="menuitem-copy-asset-link"
                disabled={isCopyAssetLinkDisabled}
                label="Copy Asset Link"
                onClick={e => handleClick(e, actionTypes.copyAssetLink)}
            />
            <ContextMenuItem
                dataTestId="menuitem-update-proxy"
                label="Update Proxy"
                onClick={e => handleClick(e, actionTypes.updateProxy)}
            />
            <ContextMenuItem dataTestId="menuitem-edit-metadata" label="Edit Metadata" onClick={clickEditMetadata} />
        </div>
    );
};

ActionsContextList.propTypes = {
    contextAsset: PropTypes.object,
    isIndividualMenu: PropTypes.bool.isRequired,
};

ActionsContextList.defaultProps = {
    contextAsset: null,
    isIndividualMenu: false,
};

export default ActionsContextList;
