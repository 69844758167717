import React from 'react';

export const useCancellablePromises = () => {
    const pendingPromises = React.useRef([]);

    const appendPendingPromise = promise => (pendingPromises.current = [...pendingPromises.current, promise]);

    const removePendingPromise = promise =>
        (pendingPromises.current = pendingPromises.current.filter(p => p !== promise));

    const clearPendingPromises = () => pendingPromises.current.map(p => p.cancel());

    const manager = {
        appendPendingPromise,
        removePendingPromise,
        clearPendingPromises,
    };

    return manager;
};

export const delay = (n = 200) => new Promise(resolve => setTimeout(resolve, n));

export const cancellablePromise = promise => {
    let isCanceled = false;

    const wrappedPromise = new Promise(async (resolve, reject) => {
        try {
            const value = await promise;
            if (isCanceled) {
                return reject({ isCanceled, value });
            }

            return resolve(value);
        } catch (error) {
            return reject({ isCanceled, error });
        }
    });

    return {
        promise: wrappedPromise,
        cancel: () => (isCanceled = true),
    };
};

export const useSingleAndDoubleClick = (onClick, onDoubleClick) => {
    const manager = useCancellablePromises();

    const handleClick = async (event, param) => {
        manager.clearPendingPromises();
        const waitForClick = cancellablePromise(delay());
        manager.appendPendingPromise(waitForClick);

        try {
            await waitForClick.promise;
            manager.removePendingPromise(waitForClick);
            param ? onClick(event, param) : onClick(event);
        } catch (errorInfo) {
            manager.removePendingPromise(waitForClick);
            if (!errorInfo.isCanceled) {
                throw errorInfo.error;
            }
        }
    };

    const handleDoubleClick = (event, param) => {
        manager.clearPendingPromises();
        param ? onDoubleClick(event, param) : onDoubleClick(event);
    };

    return [handleClick, handleDoubleClick];
};

/**
 * @example
 * const [handleClick, handleDoubleClick] = useSingleAndDoubleClick(singleClick, doubleClick);
 * <div onClick={handleClick} onDoubleClick={handleDoubleClick}>Click</div>
 */
