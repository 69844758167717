import React from 'react';
import { ChevronRight } from '@development-nl/icons-library';
import { Box, Divider, ListItemText, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { NestedMenuItem } from './NestedMenuItem';

const useStyles = makeStyles()(theme => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    spacing: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: theme.spacing(3),
        marginTop: '-2px',
    },
    itemText: {
        paddingRight: theme.spacing(1),
        alignSelf: 'center',
    },
    divider: {
        '&.MuiDivider-root': {
            margin: 0,
        },
    },
    menuItem: {
        '& .MuiListItemText-root ': {
            margin: theme.spacing(0.5, 0),
        },
    },
}));

const ContextMenuItem = React.forwardRef(
    ({ children, disabled, label, showDivider, itemIcon, menuPosition, onClick, dataTestId }, ref) => {
        const { classes } = useStyles();

        const labelCore = (
            <Box className={classes.wrapper}>
                {itemIcon}
                <ListItemText className={classes.itemText} primary={label} />
            </Box>
        );

        const onItemClick = event => {
            event.stopPropagation();
            onClick(event);
        };

        return (
            <>
                {children ? (
                    <NestedMenuItem
                        classes={{ root: classes.menuItem }}
                        data-cy="contextMenuItemElement"
                        dataTestId={dataTestId}
                        disabled={disabled}
                        isParentMenuOpen={!!menuPosition}
                        label={labelCore}
                        rightIcon={
                            <Box pl={2.5}>
                                <ChevronRight />
                            </Box>
                        }
                        onClick={onItemClick}
                    >
                        {children}
                    </NestedMenuItem>
                ) : (
                    <MenuItem
                        ref={ref}
                        classes={{ root: classes.menuItem }}
                        data-cy="contextMenuItemElement"
                        data-testid={dataTestId}
                        disabled={disabled}
                        onClick={onItemClick}
                    >
                        {labelCore}
                    </MenuItem>
                )}
                {showDivider && <Divider className={classes.divider} />}
            </>
        );
    }
);

ContextMenuItem.defaultProps = {
    children: null,
    menuPosition: null,
    showDivider: false,
    itemIcon: null,
    disabled: false,
    dataTestId: '',
    onClick: () => {},
};

ContextMenuItem.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string.isRequired,
    showDivider: PropTypes.bool,
    itemIcon: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    menuPosition: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            top: PropTypes.number.isRequired,
            left: PropTypes.number.isRequired,
        }),
        PropTypes.object,
    ]),
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string.isRequired,
};

export default ContextMenuItem;
