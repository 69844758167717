import { setToken } from '@mediabank/client';
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createEpicMiddleware, ofType } from 'redux-observable';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import advancedSearch from './advancedSearch';
import apps from './apps';
import { activeAssets, asset, assetSearchResult } from './assets';
import assetMetaKeys from './assets/metaKeys';
import breadcrumbs from './breadcrumbs';
import calendar from './calendar';
import clipLists from './clipLists/clipList';
import clipListDetails from './clipLists/clipListDetails';
import clipListEdit from './clipLists/clipListEdit';
import collections from './collections';
import companyConfig from './companyConfig';
import config from './config';
import contextMenu from './contextMenu';
import demo from './demo';
import folderEdit from './folders/folderEdit';
import folders from './folders/folders';
import markConfigDialog from './markConfigDialog';
import mBox from './mBox';
import miniEnv from './miniEnv';
import miniEnvDialog from './miniEnvDialog';
import quickfilter from './quickfilter';
import searchHappenings from './searchHappenings';
import searchProvider from './searchProvider';
import sequence from './sequence';
import events from './tagLists/events';
import happenings from './tagLists/happenings';
import tagLists from './tagLists/tagList';
import tags from './tags';
import user from './user';

const rootReducer = combineReducers({
    apps: apps.reducer,
    user: user.reducer,
    activeAssets: activeAssets.reducer,
    asset: asset.reducer,
    assetSearchResult: assetSearchResult.reducer,
    assetMetaKeys: assetMetaKeys.reducer,
    quickfilter: quickfilter.reducer,
    config: config.reducer,
    companyConfig: companyConfig.reducer,
    sequence: sequence.reducer,
    mBox: mBox.reducer,
    searchHappenings: searchHappenings.reducer,
    searchProvider: searchProvider.reducer,
    advancedSearch: advancedSearch.reducer,
    demo: demo.reducer,
    breadcrumbs: breadcrumbs.reducer,
    collections: collections.reducer,
    calendar: calendar.reducer,
    contextMenu: contextMenu.reducer,
    clipLists: clipLists.reducer,
    clipListDetails: clipListDetails.reducer,
    tagLists: tagLists.reducer,
    events: events.reducer,
    happenings: happenings.reducer,
    clipListEdit: clipListEdit.reducer,
    folderEdit: folderEdit.reducer,
    folders: folders.reducer,
    markConfigDialog: markConfigDialog.reducer,
    tags: tags.reducer,
    miniEnv: miniEnv.reducer,
    miniEnvDialog: miniEnvDialog.reducer,
});

const saveAuthToken = () => next => (action = { type: '' }) => {
    if (action.type === 'user/fetchSuccess') {
        setToken(action.payload.token);
    }

    return next(action);
};

const epicMiddleWare = createEpicMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware(), saveAuthToken, epicMiddleWare],
});

store.epic$ = new Subject();
store.injectedEpics = {};

const hotReloadingEpic = (action$, ...rest) =>
    store.epic$.pipe(mergeMap(epic => epic(action$, ...rest).pipe(takeUntil(action$.pipe(ofType('EPIC_END'))))));

epicMiddleWare.run(hotReloadingEpic);

export default store;
