import { useCallback, useEffect, useState } from 'react';
import { mbTheme } from '@mediabank/theme-v5';
import { createTheme } from '@mui/material/styles';

const useThemeSwitcher = () => {
    const appTheme = (index = 1) => createTheme(mbTheme[index]); //materialUiTheme[1]

    const [selectedTheme, setSelectedTheme] = useState(1);

    const switchTheme = useCallback(() => {
        const storedTheme = parseInt(localStorage.getItem('appTheme'));
        const isThemeNew = storedTheme && storedTheme !== selectedTheme;

        if (isThemeNew) {
            localStorage.setItem('appTheme', selectedTheme === 1 ? '2' : '1');
            setSelectedTheme(selectedTheme === 1 ? 2 : 1);
        }
    }, [selectedTheme]);

    useEffect(() => {
        const storedTheme = localStorage.getItem('appTheme');
        if (storedTheme) {
            setSelectedTheme(parseInt(storedTheme));
        } else {
            localStorage.setItem('appTheme', '1');
        }
    }, []);

    useEffect(() => {
        window.addEventListener('storage', switchTheme);

        return () => {
            window.removeEventListener('storage', switchTheme);
        };
    }, [switchTheme]);

    return [appTheme(selectedTheme)];
};

export default useThemeSwitcher;
