import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { assetsService } from '@mediabank/client';
import { ContextMenu, ContextMenuItem } from '@mediabank/uikit-v5';
import { downloadOriginal, downloadPoster, downloadProxy, safeParseJSON } from '@mediabank/utils';
import PropTypes from 'prop-types';

import { WORKSPACES } from '../../framework/constants';
import useCheckUserPermission from '../../hooks/useCheckUserPermission';
import AssetSlice from '../../store/assets/asset';
import breadcrumbs from '../../store/breadcrumbs';
import configSlice from '../../store/config';
import userSlice from '../../store/user';
import { searchWithoutTimlineOrAssetId } from '../LeftHandNav/utils';
import MboxContextList from '../MboxContextList/MboxContextList';
import ActionsContextList from './ActionsContextList';
import ConnectedAttachments from './Attachments/ConnectedAttachments';
import { usePopperMenu } from './PopperMenuContext';
import QuickLinkContextItem from './QuickLinkContextItem/QuickLinkContextItem';

const { setCurrentSearchMode } = breadcrumbs.actions;
const {
    selectors: { getConfig },
} = configSlice;

const PopperMenu = React.forwardRef(
    ({ contextAsset, assetType, menuIconClassName, position, selectedAssetsNumber, userRoles }, ref) => {
        const { AllowUserDownloadOriginal, DownloadSettings, ReactWorkspaces } = useSelector(getConfig) || {};
        const history = useHistory();
        const dispatch = useDispatch();
        const search = window?.location?.search;
        const user = useSelector(userSlice.selectors.getUser);

        const openInList = (!!ReactWorkspaces && safeParseJSON(ReactWorkspaces, WORKSPACES)) || WORKSPACES;
        const { toggleIsDeleteDialogOpen, toggleIsRestoreDialogOpen } = AssetSlice.actions;
        const { setPopperMenuPosition } = usePopperMenu();

        const { canCreateAttachment, canDeleteAsset, canViewMbox } = useCheckUserPermission();

        const assetCompanyId = contextAsset?.attributes?.assetcompany_id;
        const userCompanyId = user?.fullProfile?.attributes?.companyId;
        const isSameCompany = assetCompanyId === userCompanyId;

        const clickMenuItem = event => {
            event.stopPropagation();
            event.preventDefault();
        };

        const downloadSettings = safeParseJSON(DownloadSettings, {});
        const allDownloadsAreAllowed = downloadSettings.downloads?.default_allow || false;
        const selectedAssetId = contextAsset.id;

        const clickOriginal = e => {
            e.stopPropagation();
            e.preventDefault();
            downloadOriginal(contextAsset.attributes || contextAsset.asset);
        };

        const clickPoster = e => {
            e.stopPropagation();
            e.preventDefault();
            downloadPoster(contextAsset.attributes || contextAsset.asset);
        };

        const clickProxy = e => {
            e.stopPropagation();
            e.preventDefault();
            downloadProxy(contextAsset.attributes || contextAsset.asset);
        };

        const clickLayoutOption = async (e, path, urlParams) => {
            e.preventDefault();
            e.stopPropagation();

            if (path?.indexOf('calendar') !== -1 || path?.indexOf('collections') !== -1) {
                dispatch(setCurrentSearchMode(path?.split(',')[0]));
            }
            if (selectedAssetId) {
                let options = '';
                let timeline = '';
                let timelineOpen = '';
                let otherParams = '';

                if (contextAsset.selectedPosterCount) {
                    const response = await assetsService.getPosterSeek({
                        assetId: selectedAssetId,
                        posterIndex: contextAsset.selectedPosterCount,
                    });
                    options += `&p=${Number(response?.data?.data?.attributes?.seek * 1000)}`;
                }

                if (urlParams?.timeline) {
                    timeline = `&timeline=${urlParams.timeline}`;
                }

                if (urlParams?.timelineOpen) {
                    timelineOpen = `&timelineOpen=${urlParams.timelineOpen}`;
                }

                // we need to remove assetid and timeline configuring
                // params as those are set specifically
                otherParams = searchWithoutTimlineOrAssetId(search);

                history.push({
                    pathname: `/dashboard/${path}`,
                    search: `?assetId=${selectedAssetId}${options}${timeline}${timelineOpen}${otherParams}`,
                    state: { isLayoutSelection: true },
                });
            }
        };

        const clickDelete = e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(toggleIsDeleteDialogOpen());
        };

        const clickRestore = e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(toggleIsRestoreDialogOpen());
        };

        const checkOriginalDownload = () => {
            if (assetType === 'document') {
                return true;
            }

            if (DownloadSettings) {
                const original = downloadSettings.downloads?.exceptions?.original || [];

                return original.some(
                    originalException =>
                        originalException.roles.some(item => userRoles.includes(item)) &&
                        originalException.mime_types.includes(assetType)
                );
            } else {
                return AllowUserDownloadOriginal === 'On';
            }
        };

        const checkProxyDownload = () => {
            if (assetType === 'audio') {
                return true;
            }

            if (DownloadSettings) {
                const proxy = downloadSettings.downloads?.exceptions?.proxy || [];

                return proxy.some(
                    originalException =>
                        originalException.roles.some(item => userRoles.includes(item)) &&
                        originalException.mime_types.includes(assetType)
                );
            } else {
                return AllowUserDownloadOriginal === 'On';
            }
        };
        const isUserAllowedToDownload = allDownloadsAreAllowed || checkOriginalDownload() || checkProxyDownload();

        return (
            <ContextMenu
                data-cy="contextMenu"
                iconClassName={menuIconClassName}
                position={position}
                size="small"
                onOpen={position => setPopperMenuPosition(position)}
            >
                {selectedAssetsNumber > 0 && (
                    <ContextMenuItem
                        label={`${selectedAssetsNumber} asset${selectedAssetsNumber > 1 ? 's' : ''} selected`}
                        showDivider={true}
                        onClick={clickMenuItem}
                    />
                )}
                {selectedAssetsNumber < 2 && !!selectedAssetId && (
                    <ContextMenuItem ref={ref} label="Attachments" onClick={clickMenuItem}>
                        <ConnectedAttachments
                            canCreateAttachment={!!(canCreateAttachment && isSameCompany)}
                            canDeleteAsset={canDeleteAsset}
                            contextAsset={contextAsset}
                            mainMenuRef={ref?.current}
                            position={position}
                        />
                    </ContextMenuItem>
                )}
                {selectedAssetsNumber < 2 && isUserAllowedToDownload ? (
                    <ContextMenuItem label="Download" onClick={clickMenuItem}>
                        {(allDownloadsAreAllowed || checkOriginalDownload()) && (
                            <ContextMenuItem label="Original" onClick={clickOriginal} />
                        )}
                        {(allDownloadsAreAllowed || checkProxyDownload()) && (
                            <ContextMenuItem label="Proxy" onClick={clickProxy} />
                        )}
                        {assetType === 'image' || assetType === 'video' ? (
                            <ContextMenuItem label="Poster" onClick={clickPoster} />
                        ) : null}
                    </ContextMenuItem>
                ) : null}
                {canViewMbox && <QuickLinkContextItem contextAsset={contextAsset} />}
                {canViewMbox && (
                    <ContextMenuItem label="Add to M-Box" onClick={clickMenuItem}>
                        <MboxContextList contextAsset={contextAsset} />
                    </ContextMenuItem>
                )}
                <ContextMenuItem label="Actions" onClick={clickMenuItem}>
                    <ActionsContextList contextAsset={contextAsset} />
                </ContextMenuItem>
                {!!userRoles.find(role => role === 36) && (
                    <ContextMenuItem label="File management" onClick={clickMenuItem}>
                        <ContextMenuItem label="Restore Selected" onClick={e => clickRestore(e)} />
                    </ContextMenuItem>
                )}
                {!!selectedAssetId && selectedAssetsNumber <= 1 && (
                    <ContextMenuItem label="Open In" onClick={clickMenuItem}>
                        {!!openInList.length &&
                            openInList.map(({ name, path, urlParams }) => (
                                <ContextMenuItem
                                    key={name}
                                    label={name}
                                    onClick={e => clickLayoutOption(e, path, urlParams)}
                                />
                            ))}
                    </ContextMenuItem>
                )}
                {canDeleteAsset && <ContextMenuItem label="Delete" onClick={e => clickDelete(e)} />}
            </ContextMenu>
        );
    }
);

PopperMenu.propTypes = {
    menuIconClassName: PropTypes.string,
    selectedAssetsNumber: PropTypes.number,
    assetType: PropTypes.string,
    position: PropTypes.object,
    userRoles: PropTypes.array,
    contextAsset: PropTypes.object,
};

PopperMenu.defaultProps = {
    assetType: 'video',
    menuIconClassName: '',
    selectedAssetsNumber: 0,
    position: null,
    userRoles: [],
    contextAsset: null,
};

export default PopperMenu;
