import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@development-nl/components-library';
import { ReactComponent as LiveIcon } from '@mediabank/assets/icons/liveIcon.svg';
import { ReactComponent as ScheduledIcon } from '@mediabank/assets/icons/scheduledIcon.svg';
import { ReactComponent as VodIcon } from '@mediabank/assets/icons/vodIcon.svg';
import { assetsService } from '@mediabank/client';
import { Label } from '@mediabank/uikit-v5';
import { formatTime, getPosterImageUrl, getUTCStringDate, isAudioAsset, safeParseJSON } from '@mediabank/utils';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { WORKSPACES } from '../../framework/constants';
import useConfig from '../../hooks/useConfig/useConfig';
import useCustomNavigation from '../../hooks/useCustomNavigation';
import useDocumentPoster from '../../hooks/useDocumentPoster';
import useOuterClick from '../../hooks/useOuterClick';
import { useQueryParamsObj } from '../../hooks/useQueryParamsObj';
import useSingleAndDoubleClick from '../../hooks/useSingleAndDoubleClick';
import { assetSearchResult } from '../../store/assets';
import PopperMenu from '../PopperMenu';
import PopperMenuButton from '../PopperMenu/PopperMenuButton';
import { selectSelectedAssets } from '../SearchResult/selectors';
import { getLiveStreamMode, navigatePanels } from '../SearchResult/utils';
import Metadata from './MetaData';
import Thumbnail, { PlayerOverlay, ScrubOverlay, VideoOverlay } from './Thumbnail';

const useVideoTileStyles = makeStyles()(theme => ({
    root: {
        cursor: 'pointer',
        border: '2px solid',
        borderColor: 'transparent',
        textDecoration: 'none',
        height: '100%',
        borderRadius: theme.spacing(0, 0, 1, 1),
        '&:hover': {
            borderColor: theme.palette.border.accent,
        },
        '&:focus': {
            outline: 'none',
        },
        '& .overlayThumb span': {
            color: '#ffffff',
        },
    },
    tileContainer: {},
    selected: {
        borderColor: theme.palette.primary.main,
    },
    focused: {
        position: 'relative',
        outline: 'none',
        '&:before': {
            content: '""',
            display: 'block',
            borderTop: `4px solid ${theme.palette.action.focus}`,
            height: '4px',
            position: 'absolute',
            top: '-6px',
            left: '0px',
            width: '100%',
        },
    },
    duration: {
        fontSize: '1.1rem',
    },
    imageIcon: {
        marginTop: -theme.spacing(0.5),
        marginLeft: -theme.spacing(0.5),
    },
    isPlaying: {
        paddingBottom: '56.25%',
    },
    popperMenuIconContainer: {
        marginTop: theme.spacing(0.2),
        marginRight: theme.spacing(0.2),
        zIndex: '1',
    },
    popperMenuIcon: {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
    },
    thumbnail: {
        backgroundColor: theme.palette.background.paper,
    },
    dateThumbnail: {
        backgroundColor: theme.palette.background.dateThumbnail,
        height: '100%',
        display: 'block',
        padding: theme.spacing(1),
        whiteSpace: 'pre-line',
        lineHeight: '1.35rem',
        textAlign: 'center',
    },
    iconThumbnail: {
        position: 'relative',
        display: 'block',
        padding: theme.spacing(0.5),
        margin: '0 auto',
    },
}));

const { setAssetPosterCount } = assetSearchResult.actions;

const VideoTile = ({
    data,
    width,
    onSelect,
    assetWarnings,
    hideMetadata,
    metaList,
    setFocusedId,
    isSelected,
    onScrub,
    canScrub,
    canScrubSelectedAsset,
    checkIsAssetLoaded,
    selectedAssetsNumber,
}) => {
    const [reactWorkspaces] = useConfig('ReactWorkspaces');
    const { classes, cx } = useVideoTileStyles();
    const { history, updateQuery } = useCustomNavigation();

    const videoRef = useRef(null);
    const {
        metaData,
        posterCount,
        duration,
        isLive,
        isLocked,
        isMirrored,
        isImageAsset,
        ProgressiveURL: progressiveUrl,
        attributes,
    } = data;
    const dispatch = useDispatch();
    let { imgSrc } = data;
    const mimeTypeFull = get(attributes, 'assetmeta.MimeTypeFull');
    const isAudio = isAudioAsset(mimeTypeFull);
    const [showMetaDataOverlay, setShowMetaDataOverlay] = useState(false);
    const [showThumbnailOverlay, setShowThumbnailOverlay] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playPos, setPlayPos] = useState(0);
    const [currTime, setCurrTime] = useState(0);
    const [isScrubing, setIsScrubing] = useState(false);
    const [currentFrame, setCurrentFrame] = useState(null);
    const [currentPercentage, setCurrentPercentage] = useState();
    const [contextMenuPosition, setContextMenuPosition] = useState(null);
    const durationInSec = duration / 1000;
    const selectedAssets = useSelector(selectSelectedAssets);
    const selectedAsset = selectedAssets?.find(asset => asset.id === data.id);
    const workspacesConfig = reactWorkspaces ? safeParseJSON(reactWorkspaces, WORKSPACES) : [];

    const { LiveStreamStartTime, LiveStreamEndTime } = attributes?.assetmeta || {};
    const currentTime = new Date(Date.now()).getTime();
    const liveStreamMode = getLiveStreamMode(currentTime, LiveStreamStartTime, LiveStreamEndTime);
    const hasBottomLabel = !liveStreamMode && !!duration && !isLive && !!currTime;

    const scrumbOverlayDisplay = !liveStreamMode && !isPlaying && canScrub && isSelected && canScrubSelectedAsset;

    const [outerClickRef, setOuterClickRef] = useState(null);

    const queryParamsObj = useQueryParamsObj();
    const { assetId: openedAssetId } = queryParamsObj;

    const { image } = useDocumentPoster(attributes);

    if (image) {
        imgSrc = image;
    }

    const initUseOuterClick = useOuterClick(() => {
        if (isScrubing) {
            setIsScrubing(false);
        }
        setOuterClickRef(null);
    });

    const hoverOnMetadata = () => setShowMetaDataOverlay(true);
    const hoverOffMetadata = () => setShowMetaDataOverlay(showThumbnailOverlay);

    const hoverOnThumbnail = () => setShowThumbnailOverlay(!showMetaDataOverlay && true);
    const hoverOffThumbnail = () => setShowThumbnailOverlay(false);

    const hoverOnVideoTile = () => setOuterClickRef(null);
    const hoverOffVideoTile = () => setOuterClickRef(isScrubing ? initUseOuterClick : null);

    const urlPath = history.location.pathname;
    const isAssetLoaded = checkIsAssetLoaded(urlPath, openedAssetId, data);

    const getPosterSeek = async () => {
        try {
            if (data.id && selectedAsset) {
                const posterCount = selectedAsset?.selectedPosterCount;

                if (!posterCount) return 0;

                const {
                    data: {
                        data: {
                            attributes: { seek },
                        },
                    },
                } = await assetsService.getPosterSeek({ assetId: data.id, posterIndex: posterCount });

                return seek;
            }
        } catch (e) {
            console.error(e);
        }
    };

    const clearScrubbingPosition = () => {
        dispatch(
            setAssetPosterCount({
                assetId: data.id,
                poster: null,
                url: imgSrc,
            })
        );
        setCurrentFrame(null);
    };

    const onTileSingleClick = async event => {
        if (event.metaKey || event.ctrlKey || event.shiftKey) {
            onSelect(data.id, event);
            setFocusedId(data.id);
        } else {
            onSelect(data.id, event);
            setFocusedId(data.id);

            const seek = await getPosterSeek();

            clearScrubbingPosition();

            // updates the URL params
            updateQuery({
                assetId: data.id,
                p: playPos > 0 ? seek * 1000 : undefined,
            });

            setIsScrubing(false);

            //Navigates between panels according to current path
            navigatePanels({ history });
        }
    };

    const resolvePosterNumber = () => {
        const theURL = getPosterImageUrl(imgSrc, currentPercentage, posterCount);

        const newPosterNumber = theURL
            ? theURL?.split('?')?.[0]?.split('/')?.at(-1)?.split('_')?.at(-1)?.slice(0, -4) || 0
            : 0;

        return newPosterNumber !== 'poster' ? newPosterNumber : posterCount;
    };

    const selectThumb = event => {
        event.preventDefault();
        event.stopPropagation();
        setIsScrubing(!isScrubing);

        dispatch(
            setAssetPosterCount({
                assetId: data.id,
                poster: resolvePosterNumber(),
                url: getPosterImageUrl(imgSrc, currentPercentage, posterCount),
            })
        );
        onSelect(data.id, event);
    };

    const renderCurrentFrame = percentage => setCurrentFrame(getPosterImageUrl(imgSrc, percentage, posterCount));

    const scrubAsset = percentage => {
        const newPos = durationInSec * percentage;

        if (percentage) {
            setCurrentPercentage(percentage);
            setCurrTime(Math.round((newPos * 100) / 100));
            percentage && setPlayPos(!isNaN(newPos));
            playPos > 0 && renderCurrentFrame(percentage);
            onScrub(data.id, currTime);
        }
    };

    const play = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setIsPlaying(true);
    };

    const stop = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setIsPlaying(false);
    };

    const onTileDoubleClick = async event => {
        onSelect(data.id, event);
        setFocusedId(data.id);

        const seek = await getPosterSeek();

        clearScrubbingPosition();

        // updates the URL params
        updateQuery({
            assetId: data.id,
            p: playPos > 0 ? seek * 1000 : undefined,
        });

        setIsScrubing(false);

        // on double-click, go to the viewer
        // unless we are already there

        const workspaceConfig = workspacesConfig.find(workspaceConfig => !!workspaceConfig.assetWorkspace);

        if (workspaceConfig) {
            const pathname = `/dashboard/${workspaceConfig.path}`;
            if (!history.location.pathname.includes(pathname)) {
                history.push({ pathname, search: history.location.search, ignoreUrlDateParam: true });
            }
            if (!urlPath.includes(pathname)) {
                const originalSearchObject =
                    Object.fromEntries(new URLSearchParams(history?.location?.search || '')) || {};

                const updatedSearchObject = {
                    ...originalSearchObject,
                    ...workspaceConfig?.urlParams,
                };
                const search = Object.keys(updatedSearchObject)
                    .map(key => `${key}=${updatedSearchObject[key]}`)
                    .join('&');

                history.push({ pathname, search });
            }
        }
    };

    const onRightClick = event => {
        event.preventDefault();
        if (selectedAssets.length <= 1) {
            setContextMenuPosition({ pageX: event.pageX, pageY: event.pageY });
            onSelect(data.id, event);
            setFocusedId(data.id);
        } else {
            setContextMenuPosition({ pageX: event.pageX, pageY: event.pageY });
        }
    };

    const Icon = () => {
        switch (liveStreamMode) {
            case 'live':
                return <LiveIcon className={classes.iconThumbnail} />;
            case 'scheduled':
                return <ScheduledIcon className={classes.iconThumbnail} />;
            case 'vod':
                return <VodIcon className={classes.iconThumbnail} />;
            default:
                return <VodIcon className={classes.iconThumbnail} />;
        }
    };

    const [handleClick, handleDoubleClick] = useSingleAndDoubleClick(onTileSingleClick, onTileDoubleClick);
    const thumbnailImgSrc = currentFrame || selectedAsset?.selectedPosterURL || imgSrc;

    return (
        <Box
            ref={outerClickRef}
            className={cx(classes.root, isSelected ? classes.selected : '', isAssetLoaded ? classes.focused : '')}
            display="block"
            width={width}
            onContextMenu={onRightClick}
            onFocus={() => setFocusedId(data.id)}
            onMouseEnter={hoverOnVideoTile}
            onMouseLeave={hoverOffVideoTile}
        >
            {metaData ? (
                <div
                    className={classes.tileContainer}
                    data-cy="videoTileContainer"
                    onClick={handleClick}
                    onDoubleClick={handleDoubleClick}
                >
                    <Thumbnail.Container
                        className={`overlayThumb ${cx(classes.thumbnail, { [classes.isPlaying]: isPlaying })}`}
                        data-cy="videoTileThumbnail"
                        onMouseEnter={hoverOnThumbnail}
                        onMouseLeave={hoverOffThumbnail}
                    >
                        {!liveStreamMode && !isScrubing && isPlaying ? (
                            <VideoOverlay
                                ref={videoRef}
                                canPlay={true}
                                currTime={currTime}
                                id={data.id}
                                isAudio={isAudio}
                                isPlaying={isPlaying}
                                progressiveUrl={progressiveUrl}
                                setCurrTime={setCurrTime}
                                onPlay={play}
                                onStop={stop}
                            />
                        ) : liveStreamMode ? (
                            <Label className={classes.dateThumbnail}>
                                <Box color="primary.light" component="span">
                                    {`Live Scheduled in UTC
                                    Start: ${getUTCStringDate(new Date(parseInt(LiveStreamStartTime, 10)))}
                                    End: ${getUTCStringDate(new Date(parseInt(LiveStreamEndTime, 10)))}`}
                                </Box>
                                <Icon />
                            </Label>
                        ) : (
                            <Thumbnail.Img src={thumbnailImgSrc} title={metaData.title} />
                        )}
                        {!!hasBottomLabel && (
                            <Thumbnail.BottomLabel>
                                <Label className={classes.duration} look="black">
                                    {(isSelected || isPlaying) && (
                                        <Box color="primary.light" component="span">
                                            {formatTime(currTime)}
                                        </Box>
                                    )}
                                </Label>
                            </Thumbnail.BottomLabel>
                        )}
                        {showMetaDataOverlay && (
                            <Box onMouseEnter={hoverOnMetadata} onMouseLeave={hoverOffMetadata}>
                                <Metadata.ExtraInfoOverlay
                                    assetmeta={attributes && attributes.assetmeta}
                                    data-test="videoTile-metaData-overlay"
                                    metaDataList={metaList}
                                />
                            </Box>
                        )}

                        {!liveStreamMode && canScrub && !isScrubing && showThumbnailOverlay && (
                            <PlayerOverlay
                                canPlay={!!(duration || isLive)}
                                data-test="videoTile-overlay"
                                isPlaying={isPlaying}
                                onScrub={selectThumb}
                            />
                        )}
                        {!!contextMenuPosition && (
                            <PopperMenu
                                assetType={attributes?.assetmeta?.MimeType}
                                contextAsset={data}
                                position={contextMenuPosition}
                                selectedAssetsNumber={selectedAssetsNumber}
                            />
                        )}
                        <Box
                            className={classes.popperMenuIconContainer}
                            position="absolute"
                            right={0}
                            top={0}
                            onClick={event => {
                                event.stopPropagation();
                                onSelect(data.id, event);
                            }}
                        >
                            <PopperMenuButton iconClassName={classes.popperMenuIcon} onClick={onRightClick} />
                        </Box>

                        {!liveStreamMode && scrumbOverlayDisplay && (
                            <ScrubOverlay
                                duration={duration}
                                isScrubing={isScrubing}
                                onScrub={scrubAsset}
                                onStop={selectThumb}
                            />
                        )}
                    </Thumbnail.Container>

                    {!hideMetadata && (
                        <Box data-cy="videoTileMetaData" onMouseEnter={hoverOnMetadata} onMouseLeave={hoverOffMetadata}>
                            <Metadata.Tools
                                assetmeta={attributes && attributes.assetmeta}
                                assetWarnings={assetWarnings}
                                durationInSec={durationInSec}
                                isAudio={isAudio || false}
                                isImageAsset={isImageAsset || false}
                                isLive={isLive || false}
                                isLocked={isLocked || false}
                                isMirrored={isMirrored || false}
                                uploadCompanyName={metaData.uploadCompanyName}
                            />
                            <Metadata.Info metaData={metaData} />
                        </Box>
                    )}
                </div>
            ) : (
                <>
                    <Thumbnail.Container>
                        <Thumbnail.Skeleton />
                    </Thumbnail.Container>
                    <Metadata.Skeleton />
                </>
            )}
        </Box>
    );
};

VideoTile.propTypes = {
    width: PropTypes.string,
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    hideMetadata: PropTypes.bool,
    metaList: PropTypes.array,
    assetWarnings: PropTypes.array.isRequired,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    setFocusedId: PropTypes.func.isRequired,
    onScrub: PropTypes.func.isRequired,
    canScrub: PropTypes.bool.isRequired,
    canScrubSelectedAsset: PropTypes.bool.isRequired,
    checkIsAssetLoaded: PropTypes.func.isRequired,
    onDisplayTypeChange: PropTypes.func.isRequired,
    selectedAssetsNumber: PropTypes.number.isRequired,
    selectedPosterURL: PropTypes.string,
};

VideoTile.defaultProps = {
    width: '100%',
    loading: false,
    hideMetadata: false,
    metaList: [],
    assetWarnings: [],
    isSelected: false,
    stealFocus: () => {},
    selectedPosterURL: '',
};

export { VideoTile };
